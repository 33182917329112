<template>
  <v-dialog v-model="open">
    <v-card>
      <v-card-title>
        Export Data
        <div class="flex-grow-1"></div>
        <v-btn icon v-on:click.stop="open = false">
          <v-icon>mdi-close-circle-outline</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-select label="Install Type" v-model="dataExportInstallType"
          :items="['Replace Meter', 'Transmitter Only']"
          @change="dataExportError = ''"></v-select>
        <v-select label="Routes" v-model="exportSelectedRoutes" @change="dataExportError = ''"
          :multiple="true" :items="Object.keys(appData.metersByRoute).sort()"></v-select>
        <v-checkbox v-model="dataExportLimitDates" label="Limit to meters updated between dates"
          @change="dataExportError = ''"></v-checkbox>
        <div v-show="dataExportLimitDates" style="margin-left: 20px">
          <v-datetime-picker v-model="dataExportStartDate" label="Start Date"
              @change="dataExportError = ''">
            <template slot="dateIcon">
              <v-icon>mdi-calendar</v-icon>
            </template>
            <template slot="timeIcon">
              <v-icon>mdi-clock</v-icon>
            </template>
          </v-datetime-picker>
          <v-datetime-picker v-model="dataExportEndDate" label="End Date"
              @change="dataExportError = ''">
            <template slot="dateIcon">
              <v-icon>mdi-calendar</v-icon>
            </template>
            <template slot="timeIcon">
              <v-icon>mdi-clock</v-icon>
            </template>
          </v-datetime-picker>
        </div>
        <v-select label="Meter Types" v-model="dataExportMeterTypes"
          multiple :items="['Completed', 'Incomplete', 'Installable', 'Uninstallable']"
          @change="dataExportError = ''"></v-select>
        <div v-if="dataExportError" style="text-align: center; color: red">
          <v-icon color="red" style="padding: 20px">mdi-alert</v-icon>
          {{dataExportError}}
          <v-icon color="red" style="padding: 20px">mdi-alert</v-icon>
        </div>
        <v-btn block dark color="indigo" @click="exportData">Export Data</v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Papa from 'papaparse';
import { saveAs } from 'file-saver';
import { appData } from '../meter-data';
import appLogger from '../logger';

const logger = appLogger.getLogger('export-dialog');

export default {
    name: 'export-dialog',
    props: {
        value: Boolean,
    },
    data: function data() {
        const exportRoutes = Object.keys(appData.metersByRoute).sort();
        exportRoutes.splice(exportRoutes.indexOf('Test Meters'), 1);
        return {
            appData: appData,
            dataExportInstallType: 'Replace Meter',
            exportSelectedRoutes: exportRoutes,
            dataExportLimitDates: false,
            dataExportStartDate: undefined,
            dataExportEndDate: undefined,
            dataExportMeterTypes: ['Completed', 'Installable'],
            dataExportError: '',
        };
    },
    computed: {
        open: {
            get: function get() {
                return this.value;
            },
            set: function set(value) {
                this.$emit('input', value);
            },
        },
    },
    methods: {
        exportData: function exportData() {
            const flogger = logger.getLogger('exportData');
            flogger.info(`Exporting data for routes ${JSON.stringify(this.exportSelectedRoutes)} from ${this.dataExportStartDate} to ${this.dataExportEndDate}`);
            const csvData = [];
            for (let i = 0; i < appData.meters.length; i++) {
                const meter = appData.meters[i];
                if (meter['Install Type'] !== this.dataExportInstallType) {
                    continue;
                }
                if (!this.exportSelectedRoutes.includes(meter.Route)) {
                    continue;
                }
                const lastUpdate = new Date(meter.Version);
                if (this.dataExportLimitDates
                        && (lastUpdate < new Date(this.dataExportStartDate)
                            || lastUpdate > new Date(this.dataExportEndDate))) {
                    continue;
                }
                if (meter.Uninstallable && !this.dataExportMeterTypes.includes('Uninstallable')) {
                    continue;
                }
                if (!meter.Uninstallable && !this.dataExportMeterTypes.includes('Installable')) {
                    continue;
                }
                if (meter.Completed && !this.dataExportMeterTypes.includes('Completed')) {
                    continue;
                }
                if (!meter.Completed && !this.dataExportMeterTypes.includes('Incomplete')) {
                    continue;
                }
                const METER_SIZE_DIALS_MAP = {
                    '5/8" x 3/4"': 5,
                    '1"': 5,
                    '1.5"': 5,
                    '2"': 5,
                    '3"': 5,
                    '4"': 4,
                    '6"': 4,
                };
                // const METER_SIZE_MULTIPLIER_MAP = {
                //     '5/8" x 3/4"': 100,
                //     '1"': 100,
                //     '1.5"': 1000,
                //     '2"': 1000,
                //     '3"': 1000,
                //     '4"': 1000,
                //     '6"': 1000,
                // };
                if (this.dataExportInstallType === 'Replace Meter') {
                    csvData.push({
                        'Meter ID': meter['Southern Software Meter ID'],
                        'Meter Mfg': 'Sensus',
                        'Meter Size': meter['Meter Size'],
                        'Meter Units': 'Gallons',
                        'Number Of Dials': METER_SIZE_DIALS_MAP[meter['Meter Size']],
                        // 'Meter Multiplier': METER_SIZE_MULTIPLIER_MAP[meter['Meter Size']],
                        'Last Read': meter['Outgoing Meter Reading'],
                        'Current Reading': meter['New Meter Reading'],
                        Longitude: meter.Longitude,
                        Latitude: meter.Latitude,
                        'Install Date': meter['Install Date'],
                        'Serial No:': meter['New Meter ID'],
                        'Meter ID No': meter['New Meter ID'],
                        'TransMitter ID': meter['New Meter Transmitter ID'],
                    });
                } else { // this.dataExportInstallType === 'Transmitter Only'
                    csvData.push({
                        'Meter ID': meter['Southern Software Meter ID'],
                        'Meter Size': meter['Meter Size'],
                        Longitude: meter.Longitude,
                        Latitude: meter.Latitude,
                        'Install Date': meter['Install Date'],
                        'TransMitter ID': meter['New Meter Transmitter ID'],
                    });
                }
            }
            if (csvData.length === 0) {
                this.dataExportError = 'No Results Found';
                return;
            }
            // Specify the fields so that we have a header row, even if there is no data
            let fields = [
                'Meter ID', 'Meter Size', 'Longitude', 'Latitude', 'Install Date', 'TransMitter ID',
            ];
            if (this.dataExportInstallType === 'Replace Meter') {
                fields = fields.concat([
                    'Meter Mfg', 'Meter Units', 'Number Of Dials', 'Last Read',
                    'Current Reading', 'Serial No:', 'Meter ID No',
                ]);
            }
            const exportFileName = `Hillsville_Data_Export_${this.dataExportInstallType.replace(' ', '_')}_${new Date().toISOString().replace(':', '.')}.csv`;
            const csvStr = Papa.unparse(csvData, {
                quotes: true,
                columns: fields,
                header: true,
            });
            saveAs(new Blob([csvStr], { type: 'text/csv;charset=utf-8' }), exportFileName);
        },
    },
};
</script>
