import Vue from 'vue';
import Vuetify from 'vuetify';
import DatetimePicker from 'vuetify-datetime-picker';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import 'vuetify/dist/vuetify.min.css';
import './styles.css';
import {
    appData,
    loadAppDataFromStorage,
    saveAppDataToStorage,
} from './meter-data';
import logger from './logger';
import { APP_VERSION } from './site-consts';
import settings from './settings';

const moduleLogger = logger.getLogger('main');
moduleLogger.info(`Loading v${APP_VERSION}`);

Vue.config.productionTip = false;
Vue.config.devtools = true;

Vue.use(Vuetify);
Vue.use(DatetimePicker);
loadAppDataFromStorage().then(() => {
    window.vueApp = new Vue({
        router: router,
        data: function data() {
            return {
                aData: appData,
            };
        },
        vuetify: new Vuetify({
            // theme: {
            //     dark: true,
            // },
        }),
        render: (h) => h(App),
    }).$mount('#app');

    // Set up our watcher on our application data
    if (settings.autoSaveOnType !== false) {
        moduleLogger.debug('Setting "Auto-Save Data Changes Locally"'
            + ' is set to True. Adding Watcher.');
        window.appDataUnwatch = window.vueApp.$watch('aData', function handler() {
            saveAppDataToStorage();
        }, { deep: true });
    } else {
        moduleLogger.debug('Setting "Auto-Save Data Changes Locally"'
            + ' is set to False. Not saving.');
    }

    // Global variables for debug
    window.appData = appData;
    window.Vue = Vue;

    // logger.getLogger('main').debug('App data loaded from storage.');
    // logger.getLogger('main').debugall(`appData: ${JSON.stringify(appData)}`);
});
