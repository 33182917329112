import QueuedTransfer from './QueuedTransfer';
import rootLogger from './logger';

const logger = rootLogger.getLogger('UploadQueue');

export default class UploadQueue {
    constructor() {
        this.queue = [];
        this.inProgress = [];
        this.completed = [];
        this.running = false;
    }

    static fromJSONObj(jsonObj) {
        // Executing JSON.stringify(new UploadQueue) looks like this:
        // {
        //   "queue": [
        //     { "method": "GET", "url": "http://somurl", "bytesUploaded": 0, "totalBytesToUpload": 0, "percentUploaded": 0, "bytesDownloaded": 0, "totalBytesToDownload": 0, "percentDownloaded": 0, "complete": false, "transferring": false, "retries": 0, "maxRetries": -1, "completedCallbacks": [], "xhr": {} }
        //   ],
        //   "inProgress": [],
        //   "completed": [],
        //   "running": false
        // }
        const flogger = logger.getLogger('fromJSONObj');
        flogger.debug(`Creating UploadQueue from ${JSON.stringify(jsonObj)}`);
        const uploadQueue = new UploadQueue();
        for (let i = 0; i < jsonObj.queue.length; i++) {
            uploadQueue.addQueuedTransfer(QueuedTransfer.fromJSONObj(jsonObj.queue[i]));
        }
        for (let i = 0; i < jsonObj.inProgress.length; i++) {
            // It doesn't really make sense to load an in-progress transfer. Lets check to see if
            // there's a result. If it successfully transfered, move it to completed. Otherwise,
            // add it to the upload queue.
            // TODO test that this handles the odd cases of transfers in inProgress
            const transfer = QueuedTransfer.fromJSONObj(jsonObj.inProgress[i]);
            if (transfer.complete) {
                uploadQueue.completed.unshift(transfer);
            } else {
                uploadQueue.addQueuedTransfer(transfer);
            }
        }
        for (let i = 0; i < jsonObj.completed.length; i++) {
            uploadQueue.completed.push(QueuedTransfer.fromJSONObj(jsonObj.completed[i]));
        }
        // Don't copy the running property. We'll set that when calling startNextTransfer() next.
        return uploadQueue;
    }

    addQueuedTransfer(queuedTransfer) {
        const flogger = logger.getLogger('addQueuedTransfer');
        flogger.debug(`Pushing queuedTransfer ${queuedTransfer} onto the queue`);
        // Push on the back, take from the front
        this.queue.push(queuedTransfer);
        if (!this.running) {
            flogger.debug('Queue is not running. Starting the next transfer.');
            this.startNextTransfer();
        }
    }

    startNextTransfer() {
        const flogger = logger.getLogger('startNextTransfer');
        if (this.queue.length > 0) {
            flogger.debug('The queue is not empty. Starting the next transfer and moving it to the inProgress list.');
            this.running = true;
            this.inProgress.push(
                this.queue.shift().startTransfer(this.completedCallback.bind(this)),
            );
        } else {
            flogger.debug('The queue is empty. Setting running to false.');
            this.running = false;
        }
    }

    completedCallback(queuedTransfer) {
        const flogger = logger.getLogger('completedCallback');
        flogger.debug('Transfer completed. Moving transfer from inProgress to completed ...');
        // Remove the queued transfer from inProgress and add to completed
        this.inProgress.splice(this.inProgress.indexOf(queuedTransfer), 1);
        // We push to the front so the most recent are on top
        this.completed.unshift(queuedTransfer);
        this.startNextTransfer();
    }
}
