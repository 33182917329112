export default class QueuedTransfer {
    constructor(method, url, options = {}) {
        // Options:
        //   body (undefined)
        //   maxRetries (-1)
        //   completedCallback (undefined)
        this.name = options.name;
        this.method = method;
        this.url = url;
        this.body = options.body;
        this.bytesUploaded = 0;
        this.totalBytesToUpload = 0;
        this.percentUploaded = 0;
        this.bytesDownloaded = 0;
        this.totalBytesToDownload = 0;
        this.percentDownloaded = 0;
        this.complete = false;
        this.startedDate = undefined;
        this.completedDate = undefined;
        this.transferring = false;
        this.retries = 0;
        this.maxRetries = options.maxRetries || -1;
        this.pauseBetweenRetries = options.pauseBetweenRetries || 10000;
        this.completedCallbacks = [];
        this.attempts = 0;
        if (options.completedCallback) {
            this.completedCallbacks.push(options.completedCallback);
        }
        this.xhr = new XMLHttpRequest();
        this.xhr.upload.addEventListener('progress', (e) => {
            console.log('Upload Progress');
            console.log(e);
            this.bytesUploaded = e.loaded;
            this.totalBytesToUpload = e.total;
            if (e.loaded !== 0 && e.total !== 0) {
                this.percentUploaded = ((e.loaded / e.total) * 100).toFixed(2);
            }
            console.log(this);
        }, false);
        this.xhr.addEventListener('progress', (e) => {
            console.log('Download Progress');
            console.log(e);
            this.bytesDownloaded = e.loaded;
            this.totalBytesToDownload = e.total;
            if (e.loaded !== 0 && e.total !== 0) {
                this.percentDownloaded = ((e.loaded / e.total) * 100).toFixed(2);
            }
            console.log(this);
        }, false);
        this.xhr.onreadystatechange = async () => {
            console.log('OnReadyStateChange', this.xhr);
            // The readystate is one of ...
            //   0 UNSENT Client has been created. open() not called yet.
            //   1 OPENED open() has been called.
            //   2 HEADERS_RECEIVED send() has been called, and headers and status are available.
            //   3 LOADING Downloading; responseText holds partial data.
            //   4 DONE The operation is complete.
            if (this.xhr.readyState === 4) {
                // Done. Move on to the next one.
                this.result = {
                    statusCode: this.xhr.status,
                    responseText: this.xhr.responseText,
                };
                if (this.xhr.status < 200 || this.xhr.status >= 300) {
                    console.log(`Non-2XX Status Code (${this.xhr.status}).`);
                    if (this.maxRetries === -1 || this.retries < this.maxRetries - 1) {
                        this.retries += 1;
                        if (this.maxRetries === -1) {
                            console.log(`Requeueing to try again with attempt ${this.retries + 1} of unlimited retries in ${this.pauseBetweenRetries}ms.`);
                        } else {
                            console.log(`Requeueing to try again with attempt ${this.retries + 1} of ${this.maxRetries} in ${this.pauseBetweenRetries}ms.`);
                        }
                        this.bytesUploaded = 0;
                        this.totalBytesToUpload = 0;
                        this.percentUploaded = 0;
                        this.bytesDownloaded = 0;
                        this.totalBytesToDownload = 0;
                        this.percentDownloaded = 0;
                        setTimeout(this.startTransfer.bind(this), this.pauseBetweenRetries);
                        // this.startTransfer();
                        // If we are retrying, we aren't completed yet
                        return;
                    }
                    console.log(`Max retries (${this.maxRetries}) attempted. Giving up and marking as complete.`);
                }
                this.complete = true;
                this.completedDate = new Date();
                // We can't hold on to the body or we'll fill up our storage quickly.
                this.body = `${this.body.substring(0, 20)}...${this.body.substring(this.body.length - 20, this.body.length)}`;
                for (let i = 0; i < this.completedCallbacks.length; i++) {
                    const resp = this.completedCallbacks[i](this);
                    if (resp instanceof Promise) {
                        console.log('instance of promise');
                        // eslint-disable-next-line no-await-in-loop
                        await resp;
                    } else {
                        console.log(`Resp ${resp} from ${this.completedCallbacks[i]} not instance of Promise`);
                    }
                }
            }
        };
    }

    static fromJSONObj(jsonObj) {
        // Executing JSON.stringify(new QueuedTransfer()) looks like this:
        // { "method": "GET", "url": "http://somurl", "bytesUploaded": 0, "totalBytesToUpload": 0,
        //   "percentUploaded": 0, "bytesDownloaded": 0, "totalBytesToDownload": 0,
        //   "percentDownloaded": 0,
        //   "complete": false, "transferring": false, "retries": 0, "maxRetries": -1,
        //   "completedCallbacks": [],"xhr": {} }
        // We're going to assume the transfer needs to start over
        // console.log('Creating QueuedTransfer');
        // console.log(jsonObj);
        const queuedTransfer = new QueuedTransfer(jsonObj.method, jsonObj.url, {
            body: jsonObj.body,
            maxRetries: -1, // jsonObj.maxRetries,
            name: jsonObj.name,
            // TODO Figure out callbacks
        });
        queuedTransfer.bytesUploaded = jsonObj.bytesUploaded;
        queuedTransfer.totalBytesToUpload = jsonObj.totalBytesToUpload;
        queuedTransfer.percentUploaded = jsonObj.percentUploaded;
        queuedTransfer.bytesDownloaded = jsonObj.bytesDownloaded;
        queuedTransfer.totalBytesToDownload = jsonObj.totalBytesToDownload;
        queuedTransfer.percentDownloaded = jsonObj.percentDownloaded;
        queuedTransfer.complete = jsonObj.complete;
        if (jsonObj.completedDate) {
            queuedTransfer.completedDate = new Date(jsonObj.completedDate);
        }
        if (jsonObj.startedDate) {
            queuedTransfer.startedDate = new Date(jsonObj.startedDate);
        }
        queuedTransfer.result = jsonObj.result || { statusCode: -1, responseText: '' };
        return queuedTransfer;
    }

    startTransfer(completedCallback) {
        if (completedCallback) {
            this.completedCallbacks.push(completedCallback);
        }
        this.attempts += 1;
        this.xhr.open(this.method, this.url);
        this.xhr.withCredentials = true;
        this.startedDate = new Date();
        this.xhr.send(this.body);
        return this;
    }

    stopTransfer() {
        this.xhr.abort();
        if (this.completedCallback) {
            this.completedCallback(this);
        }
        return this;
    }

    static log(...args) {
        console.log(...args);
    }
}
