import Vue from 'vue';
import VueRouter from 'vue-router';
import meters from '../views/meters.vue';
import metersByRoute from '../views/meters-by-route.vue';
import meterDetails from '../views/meters/{meter_id}.vue';
import photo from '../views/meters/{meter_id}/photos/{photo_type}.vue';
import photoCapture from '../views/meters/{meter_id}/photos/{photo_type}/capture.vue';
import login from '../views/login.vue';
import log from '../views/log.vue';
import { appData } from '../meter-data';
import { API_BASE } from '../site-consts';
import appLogger from '../logger';

const logger = appLogger.getLogger('vue-router');
Vue.use(VueRouter);

const routes = [
    { path: '/', component: metersByRoute, meta: { requiresAuth: true } },
    { path: '/index.html', component: meters, meta: { requiresAuth: true } },
    { path: '/meters', component: meters, meta: { requiresAuth: true } },
    { path: '/meters-by-route', component: metersByRoute, meta: { requiresAuth: true } },
    { path: '/login', component: login, meta: {} },
    { path: '/log', component: log, meta: {} },
    {
        path: '/meters/:meter_id',
        component: meterDetails,
        props: true,
        meta: { requiresAuth: true },
    },
    {
        path: '/meters/:meter_id/photos/:photo_type',
        component: photo,
        props: true,
        meta: { requiresAuth: true },
    },
    {
        path: '/meters/:meter_id/photos/:photo_type/capture',
        component: photoCapture,
        props: true,
        meta: { requiresAuth: true },
    },
    /* eslint-disable */
    // To lazy load, use
    // component: () => import('../views/SomeView.vue'),
    /* eslint-enable */
];
logger.debug(`Routes Defined: ${JSON.stringify(routes)}`);

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: routes,
});

router.beforeEach(async (to, from, next) => {
    logger.info(`Navigating to route ${to.fullPath}`);
    // Solution heavily modified from https://scotch.io/tutorials/vue-authentication-and-route-handling-using-vue-router
    // If we're logged in, continue on
    if (appData.authenticated && appData.access) {
        logger.debug('appData.authenticated is true. Assuming user is properly authenticated for API calls and continuing to route.');
        return next();
    }
    logger.debug('appData.authenticated is false. Checking if any of our matched routes require authentication ...');
    // Check to see if any of the matched routes require authentication
    for (let i = 0; i < to.matched.length; i++) {
        if (to.matched[i].meta.requiresAuth) {
            // Attempt to authenticate. We might already have the access_code cookie set. We can't
            // tell b/c its an httpOnly cookie.
            // TODO Check if we really need to do this. Whenever we authenticate successfully, we
            // set appData.authenticated. And we check on every API call that we have the
            // access_code cookie.
            logger.debug('Calling GET /authenticate to see if we have the access_code cookie ...');
            // eslint-disable-next-line no-await-in-loop
            const response = await fetch(`${API_BASE}/authenticate`, {
                method: 'GET',
                mode: 'cors',
                credentials: 'include',
            }).catch(function errorHandler(error) {
                logger.error(`An error was raised when calling /authenticate: ${JSON.stringify(error)}`);
                return { ok: false };
            });
            if (response.ok) {
                appData.authenticated = true;
                // eslint-disable-next-line no-await-in-loop
                appData.access = (await response.json()).access;
                return next();
            }
            // If response isn't OK, we need to ask for login credentials. Redirect the user to the
            // /login path.
            logger.info('User is not currently authenticated. Redirecting to /login.');
            return next({
                path: '/login',
                query: { nextUrl: to.fullPath },
            });
        }
    }
    // If we aren't authenticated and no authentication is required, continue on
    return next();
});

export default router;
