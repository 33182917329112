// This file contains standard data validation functions used by the Vuetify rules property on
// many of the input components.

export function selectAtLeastOne(value) {
    if (!value || value.length === 0) {
        return 'At least one value must be selected.';
    }
    return true;
}

export function nonEmpty(value) {
    if (value === undefined || value.length === 0) {
        return 'A value must be entered.';
    }
    return true;
}

export function alwaysPass() {
    return true;
}

export const validationFuncs = {
    selectAtLeastOne: selectAtLeastOne,
    nonEmpty: nonEmpty,
    alwaysPass: alwaysPass,
};
