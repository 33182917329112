<template>
  <canvas v-if="srcIsCanvas" ref="canvas"></canvas>
  <img v-else v-bind:src="src" />
</template>

<script>
import Vue from 'vue';
import appLogger from '../logger';

const moduleLogger = appLogger.getLogger('flex-photo');
// Log levels are critical, error, warning, info, debug, and debugall
// moduleLogger.logLevel = moduleLogger.loggerLevels.debugall;

export default {
    props: {
        src: [String, HTMLCanvasElement],
    },
    data: function data() {
        return {
            // Adding a reference to HTMLCanvasElement so we can do instanceof
            // HTMLCanvasElement: HTMLCanvasElement,
            srcIsCanvas: false,
        };
    },
    created: function created() {
        const flogger = moduleLogger.getLogger('created');
        flogger.debug(`Creating a flex photo using src type ${this.src.constructor.name}: ${JSON.stringify(this.src)}. Setting srcIsCanvas to ${this.src instanceof HTMLCanvasElement}.`);
        this.srcIsCanvas = this.src instanceof HTMLCanvasElement;
    },
    mounted: function mounted() {
        this.copyCanvas();
    },
    watch: {
        src: function src(val, oldVal) {
            const flogger = moduleLogger.getLogger('watch-src');
            flogger.debug(`src changed from ${oldVal.constructor.name} (${JSON.stringify(oldVal)}) to ${val.constructor.name} (${JSON.stringify(val)})`);
            this.srcIsCanvas = this.src instanceof HTMLCanvasElement;
            // At this point, the DOM hasn't been re-rendered. So, if we're switching from a
            // non-canvas src to a canvas src, the canvas won't be rendered yet.
            // TODO Try passing copyCanvas directly to nextTick
            // Vue.nextTick(function doOnNextTick() {
            //     this.copyCanvas();
            // }.bind(this));
            Vue.nextTick(this.copyCanvas.bind(this));
        },
    },
    methods: {
        copyCanvas: function copyCanvas() {
            const flogger = moduleLogger.getLogger('copyCanvas');
            if (this.src instanceof HTMLCanvasElement) {
                // Copy the canvas element
                this.$refs.canvas.width = this.src.width;
                this.$refs.canvas.height = this.src.height;
                flogger.debug(`Copying canvas with width ${this.src.width} and height ${this.src.height}`);
                this.$refs.canvas.getContext('2d').drawImage(this.src, 0, 0);
                flogger.debug('Exiting copyCanvas()');
            } else {
                flogger.debug('The src is not a canvas. No manipulation needed.');
            }
        },
    },
};
</script>
