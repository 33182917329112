// This is intended to be a simple, minimalist logger that can be easily extended.
// Log Insight Queries:
// All messages of WARNING or higher: https://console.aws.amazon.com/cloudwatch/home?region=us-east-1#logs-insights:queryDetail=~(end~0~start~-43200~timeType~'RELATIVE~unit~'seconds~editorString~'fields*20*40timestamp*2c*20*40message*0a*7c*20filter*20level*20*3e*2020*0a*7c*20sort*20*40timestamp*20desc*0a*7c*20limit*2020~isLiveTail~false~queryId~'d6550a20-c188-4ed7-895a-a938a7a342a0~source~(~'*2fMeterSwapApp*2fClientLogs))
import Vue from 'vue';
import Logger from './libs/Logger';
import ServerLogger from './ServerLogger';

function consoleLogHandler(logMsg) {
    console.log(`${logMsg.logger.fullName} ${logMsg.level} ${logMsg.msg}`);
}

function jsonFormatter(logMsg) {
    logMsg.msg = JSON.stringify({
        logger: logMsg.logger.fullName,
        level: logMsg.level,
        msg: logMsg.msg,
        ...logMsg.additionalProps,
    });
}

const rootLogger = new Logger('app');
if (!window && process.env.LOG_LEVEL) {
    rootLogger.logLevel = process.env.LOG_LEVEL;
} else {
    rootLogger.logLevel = rootLogger.loggerLevels.info;
}
// rootLogger.use(levelHandler);
// rootLogger.use(timestampHandler);
rootLogger.use(consoleLogHandler);
rootLogger.use(jsonFormatter);
const slogger = new ServerLogger();
rootLogger.use(slogger.logMonsterHandler.bind(slogger));
window.logger = rootLogger;

// Log all uncaught errors
window.addEventListener('error', function windowErrorHandler(event) {
    rootLogger.error(
        `Uncaught exception in ${event.filename} at line ${event.lineno}, column `
        + `${event.colno}: ${event.message}`,
        { filename: event.filename, lineno: event.lineno, colno: event.colno },
    );
});
Vue.config.errorHandler = (err, vm, info) => {
    const compTree = [vm];
    while (compTree[0].$parent) {
        compTree.unshift(compTree[0].$parent);
    }
    const compPath = compTree.map((comp) => comp.$options.name).join(':');
    rootLogger.error(
        `Uncaught app error at ${compPath}: ${err.message}. Vue Info: ${info}. `
        + `Stack: ${err.stack}.`,
        { componentPath: compPath },
    );
};

export default rootLogger;

// function levelHandler(logMsg) {
//     const levelNames = [];
//     for (const [levelName, levelValue] of Object.entries(logMsg.logger.loggerLevels)) {
//         if (logMsg.level === levelValue) {
//             levelNames.push(levelName.toUpperCase());
//         }
//     }
//     let { level } = logMsg;
//     if (levelNames.length > 0) {
//         level = levelNames.join(' ');
//     }
//     logMsg.msg = `${level} ${logMsg.msg}`;
// }

// function timestampHandler(logMsg) {
//     const now = new Date();
//     const time = now.toISOString().split('T')[1];
//     logMsg.msg = `${time.substring(0, time.length - 1)} ${logMsg.msg}`;
// }

// if (!window && process.env.LOGGER_LEVELS) {
//     rootLogger.info('LOGGER_LEVELS environment variable is set. Setting log levels ...');
//     rootLogger.debug(`LOGGER_LEVELS=${process.env.LOGGER_LEVELS}`);
//     const logLevels = JSON.parse(process.env.LOGGER_LEVELS);
//     const loggers = Object.keys(logLevels);
//     for (let i = 0; i < loggers.length; i++) {
//         let logLevel = logLevels[loggers[i]];
//         rootLogger.info(`Setting logger ${loggers[i]} log level to ${logLevel}`);
//         // eslint-disable-next-line no-restricted-globals
//         if (isNaN(logLevel)) {
//             // Check to see if the user passed the name of the level
//             logLevel = rootLogger.loggerLevels[logLevel];
//             if (logLevel === undefined) {
//                 rootLogger.error(`Unknown log level ${logLevels[loggers[i]]}`);
//                 rootLogger.debug(`Logger Levels: ${JSON.stringify(rootLogger.loggerLevels)}`);
//                 continue;
//             }
//         }
//         rootLogger.getLogger(loggers[i]).logLevel = logLevel;
//     }
//     rootLogger.debug('Done setting log levels based on LOGGER_LEVELS env variable.');
// }

// const logger = rootLogger;
