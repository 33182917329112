<template>
  <v-dialog v-model="open">
    <v-card>
      <v-card-title>
        About {{WINDOW_TITLE}}
        <v-progress-circular v-show="apiAboutLoading" v-bind:indeterminate="true"
          style="margin-left: 10px"/>
        <div class="flex-grow-1"></div>
        <v-btn icon v-on:click.stop="open = false">
          <v-icon>mdi-close-circle-outline</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div>Version: {{APP_VERSION}}</div>
        <div>API Base URL: {{API_BASE}}</div>
        <div>API Base Version: {{apiBaseVersion}}</div>
        <div>API Site Version: {{apiSiteVersion}}</div>
        <div>API Code SHA256: {{apiCodeSha}}</div>
        <div># of Meters: {{meterCount}}</div>
        <div>Modified Meters: {{modifiedMetersCount}}</div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import appLogger from '../logger';
import { API_BASE, APP_VERSION, WINDOW_TITLE } from '../site-consts';
import { appData } from '../meter-data';

const moduleLogger = appLogger.getLogger('about-dialog');

export default {
    name: 'about-dialog',
    props: {
        value: Boolean,
    },
    computed: {
        open: {
            get: function get() {
                return this.value;
            },
            set: function set(value) {
                this.$emit('input', value);
            },
        },
    },
    data: function data() {
        moduleLogger.info('Gathering info for about dialog ...');
        const returnData = {
            WINDOW_TITLE: WINDOW_TITLE,
            APP_VERSION: APP_VERSION,
            API_BASE: API_BASE,
            apiAboutLoading: true,
            apiBaseVersion: '',
            apiSiteVersion: '',
            apiCodeSha: '',
            meterCount: appData.meters.length,
            modifiedMetersCount: Object.keys(appData.modifiedMeters).length,
        };
        const aboutURL = `${API_BASE}/about`;
        moduleLogger.debug(`Fetching ${aboutURL}`);
        fetch(aboutURL, { credentials: 'include' })
            .then((response) => response.json())
            .then((apiInfo) => {
                moduleLogger.debug('Response received');
                returnData.apiBaseVersion = apiInfo['Base Version'];
                returnData.apiSiteVersion = apiInfo['Site Version'];
                returnData.apiCodeSha = apiInfo['Code SHA256'];
                returnData.apiAboutLoading = false;
            });

        // {
        //     "Base Version": "1.0.1",
        //     "Site Version": "1.0.6",
        //     "Code SHA256": "e0n2Mx4pKHqX8W8g1MZcrz3HVTl5uEARh1tXiNVovcE="
        // }
        return returnData;
    },
};
</script>
