<!--

This component extends the vuetify v-text-field component to include a start/stop button that will
continuously increase the number of minutes in the text field.

Attributes:
    default: The value to use if the v-model value is undefined. If default is a function, the
        function is called with appData.lastUsedSettings as the argument.
    All other attributes are passed along to the <v-text-field> component.

This component uses v-model, which sets our value prop. And when the value changes, we call
this.$emit('input', value).

-->
<template>
  <div style="display: flex; align-items: center">
    <v-text-field v-bind="$attrs" v-model="timerValue" type="number"
      style="margin-right: 10px"></v-text-field>
    <span v-if="this.disabled"></span>
    <v-btn v-else-if="timerActive" outlined color="red" v-on:click="toggleTimer">Stop Timer</v-btn>
    <v-btn v-else outlined color="teal" v-on:click="toggleTimer">Start Timer</v-btn>
  </div>
</template>

<script>
export default {
    name: 'timer-text-field',
    props: {
        value: Number,
        disabled: Boolean,
    },
    data: function data() {
        return {
            timerActive: false,
            timerStartTime: 0,
            preTimerValue: 0,
            timerId: 0,
        };
    },
    methods: {
        updateValue: function updateValue() {
            // This method is called periodically to update the value in the text box
            if (!this.timerActive) {
                // If the timer's not active, why did we get called? Race condition? Oh well, lets
                // just exit
                return;
            }
            // When you subtract 2 dates in JavaScript, you get the number of milliseconds between
            // the two dates
            // Our value is number of hours
            const msSinceStart = new Date() - this.timerStartTime;
            if (msSinceStart === 0) {
                // We need to special case 0 or we'll get NaN when we do 0 / blah
                this.timerValue = this.preTimerValue;
            } else {
                this.timerValue = (
                    this.preTimerValue + (msSinceStart / (1000 * 60 * 60))
                ).toFixed(2);
            }
            // We're only showing two decimal digits. So, lets update our value at
            // ( 60 minutes / 100 ) x 60 seconds in a minute x 1000 ms in a second
            this.timeId = setTimeout(this.updateValue.bind(this), (60 / 100) * 60 * 1000);
        },
        toggleTimer: function toggleTimer() {
            this.timerActive = !this.timerActive;
            if (this.timerActive) {
                // We're starting the timer
                this.timerStartTime = new Date();
                this.preTimerValue = this.timerValue;
                this.updateValue();
            } else {
                // We're stopping the timer
                clearTimeout(this.timerId);
                // Do a final update of our value
                const msSinceStart = new Date() - this.timerStartTime;
                if (msSinceStart === 0) {
                    // We need to special case 0 or we'll get NaN when we do 0 / blah
                    this.timerValue = this.preTimerValue;
                } else {
                    this.timerValue = (
                        this.preTimerValue + (msSinceStart / (1000 * 60 * 60))
                    ).toFixed(2);
                }
            }
        },
    },
    computed: {
        timerValue: {
            get: function get() {
                if (this.value) {
                    return this.value;
                }
                return 0;
            },
            set: function set(value) {
                this.$emit('input', Number(value));
            },
        },
    },
};
</script>
