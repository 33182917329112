<template>
  <v-select label="Logger Module" v-model="moduleToConfigure" :items="logNames"></v-select>
</template>

<script>
import Logger from '../libs/Logger';

function loggerChildrenNames(logger) {
    const names = [];
    names.push(logger.fullName);
    const childNames = Object.keys(logger.children);
    for (let i = 0; i < childNames.length; i++) {
        names.push(...loggerChildrenNames(logger.children[childNames[i]]));
    }
    return names;
}

export default {
    name: 'logger-levels-config',
    props: {
        logger: Logger,
    },
    data: function data() {
        return {
            logNames: loggerChildrenNames(this.logger),
            moduleToConfigure: '',
        };
    },
    mounted: function mounted() {
    },
};
</script>
