<template>
  <div>
    <v-btn icon v-on:click="showBarcodeScanner = true"
        style="margin: 0 10px; color: black" :disabled="disabled">
      <v-icon size="x-large">mdi-barcode-scan</v-icon>
    </v-btn>
    <v-dialog v-model="showBarcodeScanner">
      <v-card>
        <v-card-title>
          {{title}}
          <div class="flex-grow-1"></div>
          <v-btn icon v-on:click.stop="showBarcodeScanner = false">
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <barcode-scanner v-if="showBarcodeScanner"
            :heightToWidthRatio=".24" v-on:barcodeFound="barcodeFound">
          </barcode-scanner>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import barcodeScanner from './barcode-scanner.vue';

export default {
    name: 'barcode-scan-button',
    components: {
        barcodeScanner: barcodeScanner,
    },
    props: {
        value: String,
        title: String,
        disabled: Boolean,
    },
    data: function data() {
        return {
            showBarcodeScanner: false,
        };
    },
    methods: {
        barcodeFound: function barcodeFound(barcodeResult) {
            this.$emit('input', barcodeResult.text);
            this.showBarcodeScanner = false;
        },
    },
};
</script>
