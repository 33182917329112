<template>
  <div>
    <v-app-bar app color="indigo" dark dense>
      <v-btn outlined v-on:click="backButtonPressed">
        <v-icon left>mdi-arrow-left</v-icon>Back
      </v-btn>
      <v-toolbar-title style="margin-left: 40px">{{meter['Service Address']}}</v-toolbar-title>
      <v-spacer></v-spacer>
      <span id="unsaved-changes-alert" v-if="unsavedChanges" style="cursor: pointer"
          @click="updateDataChanges(); showDataModModal = true">
        <v-icon>mdi-alert</v-icon>
        Unsaved Changes
        <v-icon>mdi-alert</v-icon>
      </span>
    </v-app-bar>
    <v-main>
      <div v-if="invalidId">Invalid ID</div>
      <div v-else style="padding: 20px">
        <div style="" id="main-content">
          <div>

            <div class="meter-section-title" style="padding-top: 10px">Existing Details</div>
            <table id="meter-details">
              <tr v-for="prop in detailProps" :key="prop.value">
                <td>{{prop.name}}:</td>
                <td>{{meter[prop.key]}}</td>
              </tr>
            </table>
            <div id="more-less-details" v-on:click="showDetails = !showDetails">
              <span v-show="!showDetails"><v-icon>mdi-menu-down</v-icon> More Details</span>
              <span v-show="showDetails"><v-icon>mdi-menu-up</v-icon> Less Details</span>
            </div>

            <div class="meter-section-title">Photos</div>
            <div id="meter-photos">
              <div v-for="photo in activePhotos" :key="photo.key">
                <div class="photo-frame">
                  <flex-photo v-if="meter[photo.key]"
                      v-bind:src="meter[photo.key]"
                      v-on:click.native="viewPhoto(photo.shortName)"
                      :id="photo.shortName + '-photo'"></flex-photo>
                  <img v-else-if="appData.access === 'RO'" style="padding: 50px"
                      :id="photo.shortName + '-placeholder'" src="/img/camera.svg" />
                  <img v-else style="padding: 50px" v-on:click="capturePhoto(photo.shortName)"
                      :id="photo.shortName + '-placeholder'" src="/img/camera.svg" />
                </div>
                <div class="photo-title">{{photo.name}}</div>
              </div>
            </div>

            <div v-if="FEATURE_TOGGLES.SHOW_GPS !== false" class="meter-section-title">
              GPS Location
            </div>
            <div v-if="meter['Latitude'] && meter['Longitude'] &&
                FEATURE_TOGGLES.SHOW_GPS !== false">
              <div style="width: 75%">
                <iframe width="100%" height="400"
                    v-bind:src="'https://maps.google.com/maps?width=100%&amp;height=600&amp;hl=en&amp;q=' + meter['Latitude'] + ',' + meter['Longitude'] + '&amp;ie=UTF8&amp;t=k&amp;z=20&amp;iwloc=B&amp;output=embed'"
                    frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
              </div>
              <div>
                Latitude: <span id="latitude">{{meter['Latitude']}}</span>, Longitude:
                <span id="longitude">{{meter['Longitude']}}</span>, Accuracy:
                {{Math.round(meter['GPS Capture Accuracy']).toLocaleString("en-US")}}m
              </div>
              <v-btn v-if="appData.access !== 'RO' && FEATURE_TOGGLES.GPS_EDITABLE"
                  id="clear-location-btn" dark color="indigo" v-on:click="clearGpsLocation"
                  style="margin-right: 10px">
                Clear Location
              </v-btn>
              <v-btn v-if="appData.access !== 'RO' && FEATURE_TOGGLES.GPS_EDITABLE"
                  id="capture-gps-btn" dark color="indigo" v-on:click="captureGpsLocation">
                Reset to Current Location
              </v-btn>
            </div>
            <div v-else-if="FEATURE_TOGGLES.SHOW_GPS !== false">
              <div style="padding: 10px 5px 10px 5px">
                No Location Captured<span id="latitude"></span><span id="longitude"></span>
              </div>
              <v-btn v-if="appData.access !== 'RO' && FEATURE_TOGGLES.GPS_EDITABLE"
                  id="capture-gps-btn" dark color="indigo" v-on:click="captureGpsLocation">
                Capture Current Location
              </v-btn>
            </div>

            <div class="meter-section-title">New Details</div>
            <v-form ref="form">
            <div v-for="prop in editableProps" :key="prop.key">
              <crew-with-hours v-if="prop.type === PROP_TYPES.CREW_WITH_HOURS"
                v-model="meter[prop.key]" :disabled="appData.access === 'RO'"
                :crew="prop.crew"
                :default="prop.defaultValue"></crew-with-hours>
              <default-select
                v-else-if="prop.type === PROP_TYPES.SELECT || prop.type === PROP_TYPES.SELECT_MULTI"
                :label="prop.name" v-model="meter[prop.key]"
                :multiple="prop.type === PROP_TYPES.SELECT_MULTI"
                :items="prop.choices"
                :rules="[propValidationFuncs[prop.key] ||
                  (prop.optional ? validationFuncs.alwaysPass : validationFuncs.selectAtLeastOne)]"
                :disabled="appData.access === 'RO'"
                :default="prop.defaultValue"></default-select>
              <v-combobox
                v-else-if="prop.type === PROP_TYPES.SELECT_EDITABLE"
                :label="prop.name" v-model="meter[prop.key]"
                :items="prop.choices" :disabled="appData.access === 'RO'"></v-combobox>
              <default-switch v-else-if="prop.type === PROP_TYPES.BOOL"
                :label="prop.name" v-model="meter[prop.key]" :default="prop.defaultValue"
                :disabled="appData.access === 'RO'"></default-switch>
              <v-textarea
                v-else-if="prop.type === PROP_TYPES.TEXTAREA"
                :label="prop.name" v-model="meter[prop.key]"
                :counter="prop.maxLength" outlined
                :disabled="appData.access === 'RO'"></v-textarea>
              <div
                  v-else-if="prop.type === PROP_TYPES.BARCODE"
                  style="display: flex; align-items: center">
                <v-text-field :label="prop.name" v-model="meter[prop.key]"
                  :disabled="appData.access === 'RO'"
                  :rules="[propValidationFuncs[prop.key] ||
                    (prop.optional ? validationFuncs.alwaysPass : validationFuncs.nonEmpty)]">
                  </v-text-field>
                <barcode-scan-button v-model="meter[prop.key]"
                  :disabled="appData.access === 'RO'"></barcode-scan-button>
                <!--v-btn icon v-on:click="showMeterBarcodeScanner = true"
                    style="margin: 0 10px; color: black" :disabled="appData.access === 'RO'">
                  <v-icon size="x-large">mdi-barcode-scan</v-icon>
                </v-btn-->
              </div>
              <date-text-field v-else-if="prop.type === PROP_TYPES.DATE" :label="prop.name"
                v-model="meter[prop.key]" :disabled="appData.access === 'RO'"></date-text-field>
              <install-type-field v-else-if="prop.type === PROP_TYPES.INSTALL_TYPE"
                :meter="meter"></install-type-field>
              <timer-text-field v-else-if="prop.type === PROP_TYPES.TIMER_TEXT"
                :label="prop.name" v-model="meter[prop.key]"
                :rules="[propValidationFuncs[prop.key]]"
                :disabled="appData.access === 'RO'"></timer-text-field>
              <dimension-field v-else-if="prop.type === PROP_TYPES.DIMENSION"
                :label="prop.name" v-model="meter[prop.key]" :disabled="appData.access === 'RO'">
                </dimension-field>
              <default-text-field v-else :label="prop.name" v-model="meter[prop.key]"
                :disabled="appData.access === 'RO'"
                :maxlength="prop.maxLength"
                :rules="[propValidationFuncs[prop.key] || validationFuncs.nonEmpty]"
                :default="prop.defaultValue"
                :type="prop.htmlType || 'text'"></default-text-field>
              <div v-if="prop.key === 'Town Attention Needed' && meter[prop.key] === true"
                  style="padding: 0 0 20px 50px">
                <div class="photo-frame" style="width: 200px">
                  <flex-photo v-if="meter['Photo URL Town Attention']"
                      v-bind:src="meter['Photo URL Town Attention']"
                      v-on:click.native="viewPhoto('town-attention')"
                      id="town-attention-photo"></flex-photo>
                  <img v-else-if="appData.access === 'RO'" style="padding: 50px"
                      id="town-attention-placeholder" src="/img/camera.svg" />
                  <img v-else style="padding: 50px" v-on:click="capturePhoto('town-attention')"
                      id="town-attention-placeholder" src="/img/camera.svg" />
                </div>
              </div>
            </div>
            </v-form>

          </div>
        </div>
        <v-btn id="submit-button" block dark color="indigo" v-on:click="checkBeforeSaveChanges"
            v-if="appData.access !== 'RO'">
          Submit
        </v-btn>
      </div>

      <v-dialog v-model="showDataWarningModal">
        <v-card>
          <v-card-title class="red--text">
            <v-icon color="red" style="margin-right: 10px">mdi-alert</v-icon> Warning
          </v-card-title>
          <v-card-text>
            Below are possible issues with the meter data. Are you sure you want to submit?
            <ul id="data-issue-list">
              <li v-for="(issue, index) in dataIssues" :key="index">{{issue}}</li>
            </ul>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey darken-1" outlined @click="showDataWarningModal = false">
              Cancel
            </v-btn>
            <v-btn color="blue darken-2" outlined @click="saveChanges">
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="showDataModModal" :retain-focus="false">
        <v-card>
          <v-card-title>Unsubmitted Changes</v-card-title>
          <v-card-text>
            The following changes haven't been submitted to the server:
            <ul>
              <li v-for="(change, index) in dataChanges" :key="index">
                {{change[0]}} was changed from "{{change[1]}}" to "{{change[2]}}"
              </li>
            </ul>
            Do you want to keep these changes?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-2" outlined
                @click="revertChanges(); showDataModModal = false">
              Revert Changes
            </v-btn>
            <v-btn color="blue darken-2" outlined @click="showDataModModal = false">
              Keep
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="showLeavingDataModModal">
        <v-card>
          <v-card-title>Unsubmitted Changes</v-card-title>
          <v-card-text>
            The following changes haven't been submitted to the server:
            <ul>
              <li v-for="(change, index) in dataChanges" :key="index">
                {{change[0]}} was changed from "{{change[1]}}" to "{{change[2]}}"
              </li>
            </ul>
            Do you want to leave without submitting? The changes will remain on this device, but
            won't be available to others.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey darken-1" outlined @click="showLeavingDataModModal = false">
              Cancel
            </v-btn>
            <v-btn color="blue darken-2" outlined
                @click="deleteModMeter(); goBack()">
              Revert Changes
            </v-btn>
            <v-btn color="blue darken-2" outlined @click="goBack">
              Continue
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- https://github.com/vuetifyjs/vuetify/issues/8459 -->
      <v-dialog v-model="showNoteWarning" :retain-focus="false">
        <v-card>
          <v-card-title>
            Notes Found
            <div class="flex-grow-1"></div>
            <v-btn icon v-on:click.stop="showNoteWarning = false">
              <v-icon>mdi-close-circle-outline</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <div style="padding: 30px 0 30px 0">{{meter['Notes']}}</div>
            <v-btn id="notes-modal-ok-btn" block dark color="indigo"
                v-on:click.stop="showNoteWarning = false">OK</v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>

    </v-main>
  </div>
</template>

<script>
// TODO Make image preview a component
// TODO Add a refresh button to load details from the server
// TODO Make the existing details an easily reusable component
import Vue from 'vue';
import {
    METER_PROPS, METER_DETAILS_PROPS, NUM_DETAILS_SHOW, FEATURE_TOGGLES,
    METER_EDIT_PROPS, PROP_TYPES, PHOTOS,
} from '../../meter-props';
import { API_BASE } from '../../site-consts';
import {
    appData, getMeterData, meterChanges, updateMeterData, updateCompletedFlag,
} from '../../meter-data';
import {
    validationFuncs,
} from '../../data-validation';
import QueuedTransfer from '../../QueuedTransfer';
import settings from '../../settings';
import flexPhoto from '../../components/flex-photo.vue';
import barcodeScanButton from '../../components/barcode-scan-button.vue';
import dateTextField from '../../components/date-text-field.vue';
import defaultTextField from '../../components/default-text-field.vue';
import defaultSelect from '../../components/default-select.vue';
import defaultSwitch from '../../components/default-switch.vue';
import installTypeField from '../../components/install-type-field.vue';
import timerTextField from '../../components/timer-text-field.vue';
import crewWithHours from '../../components/crew-with-hours.vue';
import dimensionField from '../../components/dimension-field.vue';
import appLogger from '../../logger';

const moduleLogger = appLogger.getLogger('meter-details');
// moduleLogger.logLevel = moduleLogger.loggerLevels.debug;

export default {
    name: 'MeterDetails',
    components: {
        flexPhoto: flexPhoto,
        barcodeScanButton: barcodeScanButton,
        dateTextField: dateTextField,
        defaultTextField: defaultTextField,
        defaultSelect: defaultSelect,
        defaultSwitch: defaultSwitch,
        installTypeField: installTypeField,
        timerTextField: timerTextField,
        crewWithHours: crewWithHours,
        dimensionField: dimensionField,
    },
    props: {
        meter_id: String,
    },
    data: function data() {
        // We create a new set of meter prop validation functions so that we can continue to use the
        // prototype that Vuetify requires where it calls the function with the new value, but we
        // might need to access the meter data to validate it. So, we create a custom function for
        // this meter where it has a pointer to the meter object.
        const propValidationFuncs = {};
        for (let i = 0; i < METER_EDIT_PROPS.length; i++) {
            const prop = METER_EDIT_PROPS[i];
            if (prop.validationFunc) {
                const oldFunc = prop.validationFunc;
                propValidationFuncs[prop.key] = function somefunc(value) {
                    return oldFunc(value, this.meter);
                }.bind(this);
            }
        }
        return {
            appData: appData,
            validationFuncs: validationFuncs,
            meter: {},
            unsavedChanges: false,
            invalidId: false,
            showDetails: false,
            showDataWarningModal: false,
            dataIssues: [],
            showDataModModal: false,
            showLeavingDataModModal: false,
            showNoteWarning: false,
            dataChanges: [],
            date: undefined,
            FEATURE_TOGGLES: FEATURE_TOGGLES,
            METER_EDIT_PROPS: METER_EDIT_PROPS,
            PROP_TYPES: PROP_TYPES,
            PHOTOS: PHOTOS,
            propValidationFuncs: propValidationFuncs,
        };
    },
    methods: {
        goBack: function goBack() {
            const flogger = moduleLogger.getLogger('goBack');
            flogger.debug(`window.history.length: ${window.history.length}`);
            if (window.history.length > 1) {
                flogger.info('Window history is greater than 1, going back 1 page.');
                this.$router.go(-1);
            } else {
                // The user must have gone directly to this link. There's nothing to go back to.
                // Lets go to / instead.
                flogger.info('Window history is not greater than 1, going to /.');
                this.$router.push('/');
            }
        },
        backButtonPressed: function backButtonPressed() {
            const flogger = moduleLogger.getLogger('backButtonPressed');
            // If the setting to not track typing is turned on, unsavedChanges won't be properly
            // set. So, lets go ahead and set it again.
            this.unsavedChanges = meterChanges(this.meter_id).length > 0;
            if (this.unsavedChanges) {
                flogger.info('Back button pressed, but we have unsaved changes. Showing warning modal.');
                this.updateDataChanges();
                this.showLeavingDataModModal = true;
            } else {
                flogger.info('Back button pressed and no unsaved changes. Going back 1 in history.');
                Vue.delete(appData.modifiedMeters, this.meter.ID);
                this.goBack();
            }
        },
        updateDataChanges: function updateDataChanges() {
            this.dataChanges = meterChanges(this.meter.ID);
        },
        revertChanges: function revertChanges() {
            const flogger = moduleLogger.getLogger('revertChanges');
            flogger.info('Revert Changes button pressed. Reverting changes ...');
            this.meter = JSON.parse(JSON.stringify(appData.metersById[this.meter.ID]));
            appData.modifiedMeters[this.meter.ID] = this.meter;
        },
        deleteModMeter: function deleteModMeter() {
            const flogger = moduleLogger.getLogger('deleteModMeter');
            flogger.info('Revert Changes button pressed. Removing meter from modifiedMeters list ...');
            Vue.delete(appData.modifiedMeters, this.meter.ID);
        },
        saveChanges: function saveChanges() {
            const flogger = moduleLogger.getLogger('saveChanges');
            flogger.info(`Saving changes for meter ${this.meter.ID}`);
            // If the user never touches the switch components, they'll still have an undefined
            // value, when the user thinks they have an OFF value. Lets fix that.
            updateCompletedFlag(this.meter);
            if (this.meter.Completed) {
                // Wait until the installer has completed the meter swap, then copy over the
                // defaults. This behavior will allow us to add notes without setting the defaults.
                for (let i = 0; i < METER_PROPS.length; i++) {
                    if (METER_PROPS[i].onSave) {
                        METER_PROPS[i].onSave(this.meter, appData.lastUsedSettings);
                    }
                    if (this.meter[METER_PROPS[i].key] === undefined) {
                        if (typeof METER_PROPS[i].defaultValue === 'function') {
                            // For some of our fields, like date, we need to calculate the default
                            // value
                            this.meter[METER_PROPS[i].key] = METER_PROPS[i].defaultValue(
                                appData.lastUsedSettings,
                            );
                        } else {
                            this.meter[METER_PROPS[i].key] = METER_PROPS[i].defaultValue;
                        }
                    }
                }
            }
            // TODO Make numbers numbers
            const patchBody = {
                // The only properties we need to include here are the ones not marked as
                // modifiable in site-consts
                Latitude: this.meter.Latitude,
                Longitude: this.meter.Longitude,
                'GPS Capture Accuracy': this.meter['GPS Capture Accuracy'],
                // 'Crew Members': this.meter['Crew Members'],
                // 'Outgoing Meter Reading': this.meter['Outgoing Meter Reading'],
                // 'Meter Size': this.meter['Meter Size'],
                // 'New Meter Reading': this.meter['New Meter Reading'],
                // 'New Meter ID': this.meter['New Meter ID'],
                // 'New Meter Transmitter ID': this.meter['New Meter Transmitter ID'],
                // 'Install Date': this.meter['Install Date'],
                // 'Install Type': this.meter['Install Type'],
                // 'Install Hours': this.meter['Install Hours'],
                // // 'Town Attention Needed': this.meter['Town Attention Needed'],
                // // Uninstallable: this.meter.Uninstallable,
                // 'Uninstallable Notes': this.meter['Uninstallable Notes'],
                // Notes: this.meter.Notes,
            };
            for (let i = 0; i < METER_PROPS.length; i++) {
                if (METER_PROPS[i].modifiable) {
                    if (this.meter[METER_PROPS[i].key] === undefined) {
                        continue;
                    }
                    if (METER_PROPS[i].type === PROP_TYPES.NUMBER) {
                        this.meter[METER_PROPS[i].key] = Number(this.meter[METER_PROPS[i].key]);
                    }
                    patchBody[METER_PROPS[i].key] = this.meter[METER_PROPS[i].key];
                }
            }
            flogger.info(`Fetching PATCH ${API_BASE}/meters/${this.meter.ID} ${JSON.stringify(patchBody)}`);
            appData.uploadQueue.addQueuedTransfer(new QueuedTransfer(
                'PATCH',
                `${API_BASE}/meters/${this.meter.ID}`,
                { // Options
                    name: `${this.meter['Service Address']} Data`,
                    body: JSON.stringify(patchBody),
                    completedCallback: async function completedCallback(queuedTransfer) {
                        // If the status code is 200 and the body matches our modified meter, its
                        // no longer modified.
                        if (queuedTransfer.result.statusCode !== 200) {
                            // Something went wrong with the PATCH call
                            return;
                        }
                        await updateMeterData();
                    },
                    maxRetries: -1,
                },
            ));
            if (!appData.uploadQueue.running) {
                appData.uploadQueue.startNextTransfer();
            }
            this.goBack();
        },
        viewPhoto: function viewPhoto(photoType) {
            this.$router.push(`/meters/${this.meter.ID}/photos/${photoType}`);
        },
        capturePhoto: function capturePhoto(photoType) {
            if (appData.access !== 'RO') {
                this.$router.push(`/meters/${this.meter.ID}/photos/${photoType}/capture`);
            }
        },
        captureGpsLocation: async function captureGpsLocation() {
            const flogger = moduleLogger.getLogger('captureGpsLocation');
            // TODO Give feedback to user that we're in the progress of capturing
            flogger.info('Capturing GPS location ...');
            try {
                const currentLocation = await new Promise((resolve, reject) => {
                    navigator.geolocation.getCurrentPosition(resolve, reject, {
                        enableHighAccuracy: true,
                        timeout: 5000,
                        maximumAge: 0,
                    });
                });
                flogger.info(`GPS location captured! ${currentLocation.coords.latitude}, `
                    + `${currentLocation.coords.longitude}, Accuracy `
                    + `${currentLocation.coords.accuracy}`);
                Vue.set(this.meter, 'Latitude', currentLocation.coords.latitude);
                Vue.set(this.meter, 'Longitude', currentLocation.coords.longitude);
                Vue.set(this.meter, 'GPS Capture Accuracy', currentLocation.coords.accuracy);
            } catch (error) {
                flogger.error('There was an error attempting to get the current '
                    + `location: ${error}`);
            }
        },
        clearGpsLocation: function clearGpsLocation() {
            // The Latitude and Longitude keys might not exist yet.
            this.meter.Latitude = undefined;
            this.meter.Longitude = undefined;
        },
        checkBeforeSaveChanges: function checkBeforeSaveChanges() {
            // This function is triggered when the user hits the submit button at the bottom of the
            // page. It executes all of the vuetify rules functions that we registered with the
            // form input components. This allows us to re-use the error strings. If there are any
            // errors, we display the data warning modal to give the user a chance to bail on the
            // submit, if there's an issue they didn't know about.
            this.dataIssues = [];
            // Check our vuetify fields
            const errorInputs = this.$refs.form.inputs.filter((input) => !input.validate(true));
            for (let i = 0; i < errorInputs.length; i++) {
                for (let j = 0; j < errorInputs[i].errorBucket.length; j++) {
                    this.dataIssues.push(`${errorInputs[i].label}: ${errorInputs[i].errorBucket[j]}`);
                }
            }
            // Check our images
            for (let i = 0; i < this.PHOTOS.length; i++) {
                if (this.PHOTOS[i].condition && !this.PHOTOS[i].condition(this.meter)) {
                    continue;
                }
                if (!this.meter[this.PHOTOS[i].key]) {
                    this.dataIssues.push(`No photo captured for ${this.PHOTOS[i].name}.`);
                }
            }
            if (this.dataIssues.length > 0) {
                this.showDataWarningModal = true;
            } else {
                this.saveChanges();
            }
        },
        outgoingMeterReadingValid: function outgoingMeterReadingValid(value) {
            if (this.meter['Install Type'] !== 'Replace Meter') {
                return true;
            }
            if (!value) {
                return 'The outgoing meter reading is missing.';
            }
            if (value < this.meter['Last Meter Reading']) {
                return 'The meter reading is less than the previous reading.';
            }
            if (value > this.meter['Last Meter Reading'] + (5 * this.meter['Last Consumption Amount'])) {
                return 'The meter reading shows a consumption of more than 5x what was consumed last billing cycle.';
            }
            // The current import file doesn't have a high consumption value, so we don't have a
            // way to check if the value is too high.
            // if (value > this.meter['Last Meter Reading'] + this.meter['High Consumption']) {
            //     return 'This meter consumed more than it has ever previously consumed. Are you
            // sure you read it correctly?';
            // }
            return true;
        },
        newMeterReadingValid: function newMeterReadingValid(value) {
            if (this.meter['Install Type'] !== 'Replace Meter') {
                return true;
            }
            if (value !== 0 && !value) {
                return 'The new meter reading is missing.';
            }
            if (value > 10) {
                return `Most readings on new meters are less than 10. Are you sure the value of ${value} is correct?`;
            }
            return true;
        },
        meterInstallDateValid: function meterInstallDateValid(value) {
            if (!value) {
                return 'The meter install date is missing.';
            }
            if (!value.match(/((?:0?[1-9])|(?:1[0-2]))\/((?:0?[1-9])|(?:[1-2][0-9])|(?:3[0-1]))\/(20(?:(?:19)|(?:20)))/)) {
                return 'The meter install date does not match the pattern MM/DD/YYYY.';
            }
            return true;
        },
        meterIdValid: function meterIdValid(value) {
            if (this.meter['Install Type'] !== 'Replace Meter') {
                return true;
            }
            if (!value) {
                return 'The new meter ID is missing.';
            }
            return true;
        },
        meterTransmitterIdValid: function meterTransmitterIdValid(value) {
            if (!value) {
                return 'The new meter transmitter ID is missing.';
            }
            return true;
        },
        meterUninstallableNotesValid: function meterUninstallableNotesValid(value) {
            if (this.meter.Uninstallable && !value) {
                return 'The meter was marked as uninstallable, but notes are missing explaining why.';
            }
            return true;
        },
    },
    created: async function created() {
        const flogger = moduleLogger.getLogger('created');
        // flogger.logLevel = flogger.loggerLevels.debug;
        // We have to load our meter data here instead of in the data function b/c of the async
        // calls
        flogger.debug(`Meter Edit view being created for meter ID ${this.meter_id}`);
        if (!appData.metersById[this.meter_id]) {
            flogger.debug("We're missing data for this meter. We'll try loading the data ...");
            await getMeterData();
        }
        if (!appData.metersById[this.meter_id]) {
            flogger.debug("We're still missing our data. Try updating ...");
            // If our meter still isn't there, check to see if we're missing updates
            await updateMeterData();
        }
        if (!appData.metersById[this.meter_id]) {
            // If still nothing, set invalidId
            flogger.warning('No meter was found with that ID. Setting invaid ID to true.');
            this.invalidId = true;
            return;
        }
        // We will edit a copy of the original meter to allow us to easily detect unsaved changes
        // and easily revert changes. Lets go ahead and make a copy and edit off of the copy. On
        // destroy, we'll check to see if nothing was edited and remove the copy.
        if (appData.modifiedMeters[this.meter_id]) {
            this.meter = appData.modifiedMeters[this.meter_id];
            this.updateDataChanges();
            if (this.dataChanges.length > 0) {
                flogger.info('Meter found in the modified meters list. Showing warning modal.');
                this.showDataModModal = true;
            } else {
                flogger.info('Meter found in the modified meters list, but there are no data changes. We must have exited this meter without warning previously.');
            }
        } else {
            flogger.debug('Adding meter to modifiedMeters');
            flogger.debugall(`Meter details: ${JSON.stringify(appData.metersById[this.meter_id])}`);
            // We have to use Vue.set due to the limitations of JavaScript detecting new keys
            Vue.set(
                appData.modifiedMeters,
                this.meter_id,
                JSON.parse(JSON.stringify(appData.metersById[this.meter_id])),
            );
            this.meter = appData.modifiedMeters[this.meter_id];
        }
        flogger.debug(`Meter found! Details: ${JSON.stringify(this.meter)}`);
        if (this.meter.Notes && appData.access !== 'RO') {
            this.showNoteWarning = true;
        }
    },
    activated: function activated() {
        const flogger = moduleLogger.getLogger('activated');
        flogger.debug('In activated()');
        if (this.invalidId) {
            flogger.warning(`We just activated a page with an invaid ID (${this.meter_id}. Why do we keep coming back here?`);
            return;
        }
        if (!appData.metersById[this.meter_id]) {
            flogger.error(`Unable to find ${this.meter_id} in appData.metersById. `);
            return;
        }
        if (!this.meter) {
            flogger.error('Attempting to activate a page with an invalid meter property. How did we get here?');
            return;
        }
        this.unsavedChanges = meterChanges(this.meter_id).length > 0;
    },
    computed: {
        detailProps: function detailProps() {
            const flogger = moduleLogger.getLogger('computed-detailProps');
            const propList = [];
            // To create our list of properties, we'll do a quick scrub of the
            // METER_DETAILS_PROPS to make sure we didn't put any undefined values on
            // that list.
            for (let i = 0; i < METER_DETAILS_PROPS.length; i++) {
                if (METER_DETAILS_PROPS[i] === undefined) {
                    flogger.warning(`METER_DETAILS_PROP index ${i} is undefined. Skipping.`);
                    continue;
                }
                if (METER_DETAILS_PROPS[i].name === undefined) {
                    flogger.warning(`METER_DETAILS_PROP index ${i} name attr is undefined. Skipping.`);
                    continue;
                }
                propList.push(METER_DETAILS_PROPS[i]);
            }
            if (this.showDetails) {
                return propList;
            }
            return propList.slice(0, NUM_DETAILS_SHOW);
        },
        editableProps: function editableProps() {
            return METER_EDIT_PROPS.filter(
                // Only include the property if the condition attribute is undefined or the
                // condition function returns true.
                (prop) => prop.condition === undefined || prop.condition(this.meter),
            );
        },
        activePhotos: function activePhotos() {
            return PHOTOS.filter(
                // Only include the property if the condition attribute is undefined or the
                // condition function returns true.
                (photo) => photo.condition === undefined || photo.condition(this.meter),
            );
        },
    },
    watch: {
        meter: {
            handler: function handler(val) {
                const flogger = moduleLogger.getLogger('watch-meter');
                // flogger.logLevel = flogger.loggerLevels.debug;
                flogger.debug('Changes detected on the meter object');
                flogger.debugall(`New meter value: ${JSON.stringify(val)}`);
                if (settings.checkChangesOnType !== false) {
                    // Update unsavedChanges so the alert will show in the upper right
                    this.unsavedChanges = meterChanges(this.meter_id).length > 0;
                    // meterModified(appData.metersById[this.meter_id], val);
                    if (this.unsavedChanges) {
                        flogger.debug('Changes detected between the original meter '
                            + `${JSON.stringify(appData.metersById[this.meter_id])} and the new `
                            + `meter value ${JSON.stringify(val)}`);
                    }
                } else {
                    flogger.debug('Setting "Check for Changes When Typing"'
                        + ' is False. Not checking for changes.');
                }
            },
            deep: true,
        },
    },
};
</script>
