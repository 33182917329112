<!---
This filter bar is used in the /meters view and also in the Exports dialog. Its used to filter
the meters in a list. It modifies a filter spec provided in the v-model attribute.

TODO Optimize slot to not call choices() choices number of times. Maybe with a new
     component for this type of select.
---->
<template>
  <div>
    <div v-for="(filter, filterIndex) in value" v-bind:key="filterIndex" class="filter-choice">
        <v-select v-if="filter.prop.type === FILTER_TYPES.SELECT_MULTI"
            :label="filter.prop.label"
            v-model="filter.value"
            multiple
            :items="filter.prop.choices(unfilteredData)">
          <template v-slot:selection="{ index }">
            <span v-if="index === 0" class="grey--text caption">
                {{ filter.value.length }} of
                {{filter.prop.choices(unfilteredData).length}} Selected
            </span>
          </template>
        </v-select>
        <v-select v-if="filter.prop.type === FILTER_TYPES.BOOL"
            :label="filter.prop.label"
            v-model="filter.value"
            multiple
            :items="[{ text: 'Yes', value: true }, { text: 'No', value: false }]">
          <template v-slot:selection="{ index }">
            <span v-if="index === 0" class="grey--text caption">
                {{ filter.value.length }} of 2 Selected
            </span>
          </template>
        </v-select>
    </div>
  </div>
</template>

<script>
import { FILTER_TYPES } from '../filter-props';

// No need for a logger right now. Uncomment if we find a need.
// import logger from '../logger';
// const moduleLogger = logger.getLogger('filter-bar');
// moduleLogger.logLevel = moduleLogger.loggerLevels.debug;

export default {
    name: 'filter-bar',
    props: {
        value: Array,
        unfilteredData: Array,
    },
    data: function data() {
        return {
            FILTER_TYPES: FILTER_TYPES,
        };
    },
};
</script>
