// This file contains data/code for filtering our meters. I expect there to be some customization
// between projects. But, also a lot of reuse.
// import { appData } from './meter-data';
import appLogger from './logger';

const mlogger = appLogger.getLogger('filter-props');

const FILTER_TYPES = {
    SELECT_MULTI: 'Select Multi',
    BOOL: 'Bool',
};

class UniqueValuesChoices {
    constructor(key) {
        this.key = key;
        this.cachedChoices = undefined;
        // If we don't bind choices, this will end up being the filter prop object and key
        // won't be found
        this.choices = this.choices.bind(this);
    }

    choices(data) {
        const flogger = mlogger.getLogger('unique-values-choices');
        if (this.cachedChoices === undefined) {
            flogger.debug(`Choices for ${this.label} filter have not been cached. Calculating choices ...`);
            if (data === undefined) {
                flogger.error('Argument passed to choices() is undefined. We expected a list of our data. Returning empty array.');
                return [];
            }
            const uniqueChoices = new Set();
            flogger.debug('Looking for unique values for our choices');
            data.forEach((item) => {
                if (Array.isArray(item[this.key])) {
                    item[this.key].forEach((subitem) => uniqueChoices.add(subitem));
                } else if (item[this.key] === undefined) {
                    uniqueChoices.add('Not Specified');
                } else {
                    uniqueChoices.add(item[this.key]);
                }
            });
            this.cachedChoices = Array.from(uniqueChoices).sort();
        }
        return this.cachedChoices;
    }
}

function defaultValueAll(choices) {
    const flogger = mlogger.getLogger('default-value-all');
    flogger.debug(`Calculating default value from ${choices}`);
    if (choices === undefined) {
        flogger.error('We expected choices to be given. Without it, we can\'t calculate our default value.');
        return undefined;
    }
    return choices.map((choice) => choice.value || choice);
}

function standardSelectFilter(key, label) {
    return {
        label: label || key,
        type: FILTER_TYPES.SELECT_MULTI,
        choices: new UniqueValuesChoices(key).choices,
        defaultValue: defaultValueAll,
        filter: function filter(meter, selectedValues) {
            return !selectedValues.includes(meter[key] || 'Not Specified');
        },
    };
}

function standardSelectMultiFilter(key, label) {
    return {
        label: label || key,
        type: FILTER_TYPES.SELECT_MULTI,
        choices: new UniqueValuesChoices(key).choices,
        defaultValue: defaultValueAll,
        filter: function filter(meter, selectedValues) {
            let meterVal = meter[key];
            if (!Array.isArray(meterVal) || meterVal.length === 0) {
                meterVal = ['Not Specified'];
            }
            for (let i = 0; i < meterVal.length; i++) {
                if (selectedValues.includes(meterVal[i])) {
                    return false;
                }
            }
            return true;
        },
    };
}

function standardBoolFilter(key, label) {
    return {
        label: label || key,
        type: FILTER_TYPES.SELECT_MULTI,
        choices: function choices() {
            return [
                { text: 'Yes', value: true },
                { text: 'No', value: false },
            ];
        },
        defaultValue: function defaultValue() {
            return [true, false];
        },
        filter: function filter(meter, selectedValues) {
            return !selectedValues.includes(meter[key] || false);
        },
    };
}

const props = [
    {
        label: 'Routes',
        type: FILTER_TYPES.SELECT_MULTI,
        choices: new UniqueValuesChoices('Route Name').choices,
        defaultValue: function defaultValue(choices) {
            const flogger = mlogger.getLogger('routes-default');
            if (choices === undefined) {
                flogger.error('We expected choices to be given. Without it, we can\'t calculate our default value.');
                return undefined;
            }
            return choices.filter((choice) => choice !== 'Test Meters');
        },
        filter: function filter(meter, selectedValues) {
            return !selectedValues.includes(meter['Route Name']);
        },

    },
    // TODO Crew Members
    standardSelectMultiFilter('Parts Needed'),
    standardSelectFilter('Meter Box Shape'),
    standardSelectFilter('Meter Box Type'),
    standardBoolFilter('Leak Present'),
    standardBoolFilter('Meter Needs to be Cleaned Out', 'Needs Cleaning'),
    standardSelectMultiFilter('Digging Needed'),
    standardBoolFilter('In Asphalt'),
    standardBoolFilter('In Concrete'),
    standardSelectFilter('Yoke'),
    standardSelectFilter('Meter Size'),
    standardBoolFilter('Check Valve'),
    standardBoolFilter('Cut Off'),
    standardBoolFilter('Too Deep'),
    standardBoolFilter('Too Shallow'),
    standardBoolFilter('E-911 Address Incorrect', 'Address Incorrect'),
    standardSelectMultiFilter('Parts Used'),
    standardSelectFilter('Install Date'),
    standardBoolFilter('Town Attention Needed'),
    // {
    //     label: 'Meter Box Shape',
    //     type: FILTER_TYPES.SELECT_MULTI,
    //     choices: new UniqueValuesChoices('Meter Box Shape').choices,
    //     defaultValue: defaultValueAll,
    //     filter: function filter(meter, selectedValues) {
    //         return !selectedValues.includes(meter['Meter Box Shape'] || 'Not Specified');
    //     },
    // },
    // Existing examples
    {
        label: 'Install Type',
        type: FILTER_TYPES.SELECT_MULTI,
        choices: function choices() {
            return [
                'Standard Meter Replacement',
                'Hourly Rate',
            ];
        },
        defaultValue: function defaultValue() {
            return [
                'Standard Meter Replacement',
                'Hourly Rate',
            ];
        },
        filter: function filter(meter, selectedValues) {
            // To determine the type of install, we have to look at the crew members and see if
            // they have any hours.
            const crew = meter['Crew Members'];
            if (crew === undefined || !Array.isArray(crew) || crew.length === 0
                || crew[0].Hours === undefined) {
                // This meter is a standard meter replacement
                return !selectedValues.includes('Standard Meter Replacement');
            }
            for (let i = 0; i < crew.length; i++) {
                if (crew[i].Hours !== undefined && crew[i].Hours > 0) {
                    // This meter is an hourly rate
                    return !selectedValues.includes('Hourly Rate');
                }
            }
            // If we got this far, we didn't find any hours. This meter is a standard meter
            // replacement.
            return !selectedValues.includes('Standard Meter Replacement');
        },
    },
    // {
    //     label: 'Meter Size',
    //     type: FILTER_TYPES.SELECT_MULTI,
    //     choices: function choices() {
    //         return [
    //             { text: '0"', value: '0' },
    //             { text: '5/8"', value: '0.58' },
    //             { text: '3/4"', value: '0.75' },
    //             { text: '1"', value: '1' },
    //             { text: '1.25"', value: '1.25' },
    //             { text: '1.5"', value: '1.5' },
    //             { text: '2"', value: '2' },
    //             { text: '3"', value: '3' },
    //             { text: '4"', value: '4' },
    //             { text: '5"', value: '5' },
    //             { text: '6"', value: '6' },
    //             { text: '7"', value: '7' },
    //             { text: '8"', value: '8' },
    //             { text: 'Not Specified', value: 'Not Specified' },
    //         ];
    //     },
    //     defaultValue: function defaultValue() {
    //         return ['0', '0.58', '0.75', '1', '1.25', '1.5', '2', '3', '4', '5', '6', '7', '8',
    //             'Not Specified'];
    //     },
    //     filter: function filter(meter, selectedValues) {
    //         const size = meter['Meter Size'] || 'Not Specified';
    //         return !selectedValues.includes(size);
    //     },
    // },
    // {
    //     label: 'New Meter Size',
    //     type: FILTER_TYPES.SELECT_MULTI,
    //     choices: function choices() {
    //         return [
    //             { text: '0"', value: '0' },
    //             { text: '5/8"', value: '0.58' },
    //             { text: '3/4"', value: '0.75' },
    //             { text: '1"', value: '1' },
    //             { text: '1.25"', value: '1.25' },
    //             { text: '1.5"', value: '1.5' },
    //             { text: '2"', value: '2' },
    //             { text: '3"', value: '3' },
    //             { text: '4"', value: '4' },
    //             { text: '5"', value: '5' },
    //             { text: '6"', value: '6' },
    //             { text: '7"', value: '7' },
    //             { text: '8"', value: '8' },
    //             { text: 'Not Specified', value: 'Not Specified' },
    //         ];
    //     },
    //     defaultValue: function defaultValue() {
    //         return ['0', '0.58', '0.75', '1', '1.25', '1.5', '2', '3', '4', '5', '6', '7', '8',
    //             'Not Specified'];
    //     },
    //     filter: function filter(meter, selectedValues) {
    //         const size = meter['New Meter Size'] || 'Not Specified';
    //         return !selectedValues.includes(size);
    //     },
    // },
    // {
    //     label: 'Town Attention Needed',
    //     type: FILTER_TYPES.SELECT_MULTI,
    //     choices: function choices() {
    //         return [
    //             { text: 'Yes', value: true },
    //             { text: 'No', value: false },
    //         ];
    //     },
    //     defaultValue: function defaultValue() {
    //         return [true, false];
    //     },
    //     filter: function filter(meter, selectedValues) {
    //         const attentionNeeded = meter['Town Attention Needed'] || false;
    //         return !selectedValues.includes(attentionNeeded);
    //     },
    // },
    // {
    //     label: 'Completed',
    //     type: FILTER_TYPES.SELECT_MULTI,
    //     choices: function choices() {
    //         return [
    //             { text: 'Yes', value: true },
    //             { text: 'No', value: false },
    //         ];
    //     },
    //     defaultValue: function defaultValue() {
    //         return [true, false];
    //     },
    //     filter: function filter(meter, selectedValues) {
    //         const completed = meter.Completed || false;
    //         return !selectedValues.includes(completed);
    //     },
    // },
    // {
    //     label: 'Unsaved Changes',
    //     type: FILTER_TYPES.SELECT_MULTI,
    //     choices: function choices() {
    //         return [
    //             { text: 'Yes', value: true },
    //             { text: 'No', value: false },
    //         ];
    //     },
    //     defaultValue: function defaultValue() {
    //         return [true, false];
    //     },
    //     filter: function filter(meter, selectedValues) {
    //         return !selectedValues.includes(
    //             appData.modifiedMeters[meter.ID] !== undefined,
    //         );
    //     },
    // },
    // {
    //     label: 'Parts Used',
    //     type: FILTER_TYPES.SELECT_MULTI,
    //     choices: function choices() {
    //         return [
    //             '7" Resetter',
    //             '9" Resetter',
    //             '12" Resetter',
    //             'Retro Resetter',
    //             '3/4" Meter Adapter',
    //             'Tail Pieces',
    //             'None',
    //         ];
    //     },
    //     defaultValue: function defaultValue() {
    //         return [
    //             '7" Resetter',
    //             '9" Resetter',
    //             '12" Resetter',
    //             'Retro Resetter',
    //             '3/4" Meter Adapter',
    //             'Tail Pieces',
    //             'None',
    //         ];
    //     },
    //     filter: function filter(meter, selectedValues) {
    //         let partsUsed = meter['Parts Used'];
    //         if (!Array.isArray(partsUsed) || partsUsed.length === 0) {
    //             partsUsed = ['None'];
    //         }
    //         for (let i = 0; i < partsUsed.length; i++) {
    //             if (selectedValues.includes(partsUsed[i])) {
    //                 return false;
    //             }
    //         }
    //         return true;
    //     },
    // },
    // {
    //     label: 'In Export',
    //     type: FILTER_TYPES.SELECT_MULTI,
    //     choices: function choices() {
    //         // Gather all the export values. Alternatively, we could call /api/exports to
    //         // get a list.
    //         if (!this.cachedExportIds) {
    //             const exportIds = new Set();
    //             for (let i = 0; i < appData.meters.length; i++) {
    //                 const exports = appData.meters[i].Exports;
    //                 if (exports === undefined || !Array.isArray(exports)
    //                     || exports.length === 0) {
    //                     continue;
    //                 }
    //                 for (let j = 0; j < exports.length; j++) {
    //                     exportIds.add(exports[j]);
    //                 }
    //             }
    //             this.cachedExportIds = Array.from(exportIds);
    //             this.cachedExportIds.push('Not Exported');
    //         }
    //         return this.cachedExportIds;
    //     },
    //     defaultValue: function defaultValue(choices) {
    //         return [...choices];
    //     },
    //     filter: function filter(meter, selectedValues) {
    //         const exports = meter.Exports;
    //         if (exports === undefined || !Array.isArray(exports) || exports.length === 0) {
    //             return !selectedValues.includes('Not Exported');
    //         }
    //         for (let i = 0; i < exports.length; i++) {
    //             if (selectedValues.includes(exports[i])) {
    //                 return false;
    //             }
    //         }
    //         return true;
    //     },
    // },
];

export { FILTER_TYPES, props };
