<template>
  <div>
    <v-navigation-drawer v-model="drawer" v-if="!$route.meta.hideNav" app>
      <v-list dense>
        <v-list-item v-on:click.stop="showImportWizard = true; drawer = !drawer">
          <v-list-item-action>
            <v-icon>mdi-upload</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Import Route</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="showDataExport = true; drawer = !drawer">
          <v-list-item-action>
            <v-icon>mdi-file-download-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
              <v-list-item-title>Export Data</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="clearDeviceCache">
          <v-list-item-action>
            <v-icon>mdi-delete-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Clear Device Cache</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="testErrorThrow">
          <v-list-item-action>
            <v-icon>mdi-delete-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Test error catch</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app v-if="!$route.meta.hideNav" color="indigo" dark dense>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title id="toolbar-title">Hillsville Meter Swap</v-toolbar-title>
      <div class="flex-grow-1"></div>
      <v-btn icon style="margin-right: 20px" v-on:click.stop="showUploadQueue = true">
        <v-badge v-if="inProgressUploads.length + queuedUploads.length > 0" color="orange"
            overlap>
        <template v-slot:badge>{{inProgressUploads.length + queuedUploads.length}}</template>
        <v-icon>mdi-cloud-upload</v-icon>
        </v-badge>
        <v-icon v-else>mdi-cloud-upload</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <logger-levels-config :logger="logger"></logger-levels-config>
    </v-main>
  </div>
</template>

<script>
import Papa from 'papaparse';
import { saveAs } from 'file-saver';
import { appData, createMeters, updateMeterData } from '../meter-data';
import logger from '../logger';
import UploadQueue from '../UploadQueue';
import loggerLevelsConfig from '../components/logger-levels-config.vue';

export default {
    name: 'view-meters',
    components: {
        loggerLevelsConfig: loggerLevelsConfig,
    },
    data: function data() {
        const exportRoutes = Object.keys(appData.metersByRoute).sort();
        exportRoutes.splice(exportRoutes.indexOf('Test Meters'), 1);
        return {
            logger: logger,
            search: '',
            loading: false,
            drawer: false,
            appData: appData,
            showCompleted: false,
            showFullWidthSearch: false,
            lastUpdateStr: 'Never',
            updateStrIntervalId: 0,
            showUploadQueue: false,
            uploadToDetail: undefined,
            queuedUploads: appData.uploadQueue.queue,
            inProgressUploads: appData.uploadQueue.inProgress,
            completedUploads: appData.uploadQueue.completed,
            showImportWizard: false,
            showDataExport: false,
            dataExportInstallType: 'Replace Meter',
            exportSelectedRoutes: exportRoutes,
            dataExportLimitDates: false,
            dataExportStartDate: undefined,
            dataExportEndDate: undefined,
            dataExportMeterTypes: ['Completed', 'Installable'],
            dataExportError: '',
            importFormat: 'CSV',
            importRouteName: '',
            importRecords: [],
            importPreviewHeaders: [],
            importPreviewRecords: [],
            importFormatString: '{Cycle:n:2}'
                              + '{Route:n:2}'
                              + '{Read/Walk Sequence:n:5}'
                              + '{Service Description:s:20}'
                              + '{SDP/Socket ID:s:14}'
                              + '{Customer Account (CSID+LCID):s:19}'
                              + '{Customer ID:s:9}'
                              + '{Location/Premise ID:s:9}'
                              + '{Old Meter Number:s:12}'
                              + '{Install Date:s:10}'
                              + '{Register or Body Serial Number:s:12}'
                              + '{High Consumption:s:12}'
                              + '{Low Consumption:s:12}'
                              + '{Rate Class / Account Type:s:25}'
                              + '{Customer Phone:s:13}'
                              + '{Customer Name:s:30}'
                              + '{Service Address:s:48}'
                              + '{City:s:30}'
                              + '{State:s:2}'
                              + '{Zip:s:10}'
                              + '{Account Status:s:25}'
                              + '{Mailing Address 1:s:48}'
                              + '{Mailing Address 2:s:48}'
                              + '{Mailing City:s:30}'
                              + '{Mailing State:s:2}'
                              + '{Mailing Zip Code:s:9}'
                              + '{Register Name / Read Units:s:4}'
                              + '{Previous Read:s:13}'
                              + '{Previous Reading Date:s:10}'
                              + '{Old Meter Radio ID:s:12}'
                              + '{Touch Read Register Number:s:12}'
                              + '{Old Meter Size Code:s:4}'
                              + '{Old Meter Size Description:s:25}'
                              + '{Old Meter Make:s:25}'
                              + '{Meter Location:s:35}'
                              + '{Specific Meter Location:s:25}'
                              + '{Additional Meter Location Info:s:200}'
                              + '{Latitude:s:16}'
                              + '{Longitude:s:16}',
            importCSVConversionString: '{Meter ID:s:Outgoing Meter ID}'
                                     + '{Account Number:s:Account Number}'
                                     + '{Name:s:Customer Name}'
                                     + '{ServiceAddr:s:Service Address}'
                                     + '{Meter Type:s:Outgoing Meter Type}'
                                     + '{Meter Mfg:s:Outgoing Meter Manufacturer}'
                                     + '{Meter Size:s:Outgoing Meter Size}'
                                     + '{Meter Units:s:Outgoing Meter Units}'
                                     + '{Number Of Dials:s:Number Of Dials on Outgoing Meter}'
                                     + '{Meter Multiplier:n:Outgoing Meter Multiplier}'
                                     + '{Last Curr Read:n:Outgoing Meter Previous Reading}'
                                     + '{Last Read:n:Outgoing Meter Reading}'
                                     + '{Current Reading:n:New Meter Reading}'
                                     + '{Previous Reading:n:New Meter Previous Reading}'
                                     + '{Serial No::s:Outgoing Meter Serial}'
                                     // We've already captured the outgoing meter ID with the first
                                     // field. I see no point in capturing it again.
                                     // + '{Meter ID No:s:Outgoing Meter ID}'
                                     + '{TransMitter ID:s:Outgoing Meter Transmitter ID}'
                                     // TODO Check if we will be getting any latitude longitude data
                                     + '{Longitude:n:Longitude}'
                                     + '{Latitude:n:Latitude}'
                                     + '{Install Date:s:Install Date}',
        };
    },
    computed: {
        // a computed getter
        headers: function headers() {
            const returnVal = [
                {
                    text: 'Location Number',
                    align: 'center',
                    sortable: true,
                    value: 'Location Number',
                    class: 'd-none d-sm-table-cell',
                },
                {
                    text: 'Install Type',
                    align: 'center',
                    sortable: true,
                    value: 'Install Type',
                    class: 'd-none d-sm-table-cell',
                },
                {
                    text: 'Meter Size',
                    align: 'center',
                    sortable: true,
                    value: 'Meter Size',
                    class: 'd-none d-sm-table-cell',
                },
                // {
                //     text: 'Outgoing Meter ID',
                //     align: 'center',
                //     sortable: true,
                //     value: 'Outgoing Meter ID',
                //     class: 'd-none d-sm-table-cell',
                // },
                {
                    text: 'Service Address',
                    align: 'left',
                    sortable: false,
                    value: 'Service Address',
                    class: 'd-none d-sm-table-cell',
                },
            ];
            if (this.showCompleted) {
                returnVal.unshift({
                    text: 'Completed',
                    align: 'center',
                    sortable: true,
                    value: 'Completed',
                    class: 'd-none d-sm-table-cell',
                });
            }
            // `this` points to the vm instance
            return returnVal;
        },
    },
    methods: {
        testErrorThrow: function testErrorThrow() {
            throw new Error('some error');
        },
        goToMeterDetails: function goToMeterDetails(meterId) {
            this.$router.push(`/meters/${meterId}`);
        },
        updateData: async function updateData() {
            this.loading = true;
            try {
                await updateMeterData();
            } catch (err) {
                console.log(err);
                // alert(`Unexpected error during update: ${err.message}`);
            }
            this.loading = false;
            clearInterval(this.updateStrIntervalId);
            this.updateLastUpdateStr();
        },
        calcRoutePercentComplete: function calcRoutePercentComplete(routeName) {
            let numComplete = 0;
            const meters = this.appData.metersByRoute[routeName];
            for (let i = 0; i < meters.length; i++) {
                if (meters[i].Completed) {
                    numComplete++;
                }
            }
            if (numComplete === 0) {
                return 0;
            }
            return Math.round((numComplete / meters.length) * 100);
        },
        processImportFile: function processImportFile(file) {
            const flogger = this.logger.getLogger('processImportFile');
            flogger.info('Processing the file to import.');
            // Process the importFormatString
            const reader = new FileReader();
            this.importRecords = [];
            this.importPreviewHeaders = [];
            this.importPreviewRecords = [];
            reader.onload = function onload(e) {
                const fileContents = e.target.result;
                flogger.info(`Import Format: ${this.importFormat}`);
                flogger.info(`Setting route to ${this.importRouteName}`);
                if (this.importFormat === 'Fixed Width') {
                    const lines = fileContents.split(/\r\n|\n|\r/);
                    flogger.info(`Import Format String: ${this.importFormatString}`);
                    const formatFields = [...this.importFormatString.matchAll(/\{([^:]*):([sSnN]):([0-9]*)\}/g)];
                    for (let i = 0; i < formatFields.length; i++) {
                        this.importPreviewHeaders.push({
                            text: formatFields[i][1],
                            value: formatFields[i][1],
                            class: 'nowrap',
                        });
                    }
                    for (let i = 0; i < lines.length; i++) {
                        const record = {};
                        let charsRead = 0;
                        for (let j = 0; j < formatFields.length; j++) {
                            const fieldLen = parseInt(formatFields[j][3], 10);
                            // TODO Check that its a number
                            let value = lines[i].substr(charsRead, fieldLen).trim();
                            if (formatFields[j][3].toLowerCase() === 'n') {
                                value = parseFloat(value);
                                // TODO Check that its a number
                            }
                            record[formatFields[j][1]] = value;
                            charsRead += fieldLen;
                        }
                        this.importRecords.push(record);
                        if (i < 3) {
                            this.importPreviewRecords.push(record);
                        }
                    }
                    console.log(this.importRecords[0]);
                } else if (this.importFormat === 'CSV') {
                    // const conversions = [...this.importCSVConversionString.matchAll(
                    //     /\{(.*):([sSnN]):(.*)\}/g)]
                    flogger.info(`CSV Conversion String: ${this.importCSVConversionString}`);
                    const conversions = [...this.importCSVConversionString.matchAll(/\{(.*?)\}/g)];
                    flogger.debug(`Conversion list: ${JSON.stringify(conversions)}`);
                    // The conversion map has keys of the CSV mapped to an object that tells us
                    // what the new field name should be and what type of variable it is.
                    const conversionMap = {};
                    for (let i = 0; i < conversions.length; i++) {
                        const convFields = conversions[i][1].match(/(.*):([sSnN]):(.*)/);
                        conversionMap[convFields[1]] = {
                            type: convFields[2],
                            newFieldName: convFields[3],
                        };
                    }
                    flogger.debug(`Conversion map: ${JSON.stringify(conversionMap)}`);
                    flogger.debug('Parsing CSV file with papaparse ...');
                    const results = Papa.parse(fileContents, {
                        header: true,
                    });
                    flogger.debug('Papa parse complete.');
                    flogger.debugall(`Papa parse results: ${JSON.stringify(results)}`);
                    // Convert our field names
                    const fields = Object.keys(conversionMap);
                    for (let i = 0; i < results.data.length; i++) {
                        const record = results.data[i];
                        flogger.debugall(`Converting record ${JSON.stringify(record)}`);
                        // TODO Optimize this to only look at the keys we care about
                        for (let j = 0; j < fields.length; j++) {
                            const fieldName = fields[j];
                            flogger.debugall(`Converting field ${fieldName}`);
                            if (!record[fieldName]) {
                                // If the field doesn't exist in our conversionMap, skip it
                                flogger.debugall('Field does not exist in the record. Skipping.');
                                continue;
                            }
                            const conversionProps = conversionMap[fieldName];
                            flogger.debugall(`Conversion props: ${JSON.stringify(conversionProps)}`);
                            if (conversionProps.type.toLowerCase() === 'n') {
                                flogger.debugall('Conversion type is number. Converting to float.');
                                record[fieldName] = parseFloat(record[fieldName]);
                            }
                            if (fieldName !== conversionProps.newFieldName) {
                                // The field name needs to be changed
                                record[conversionProps.newFieldName] = record[fieldName];
                                delete record[fieldName];
                            }
                            flogger.debugall('Conversion complete for this field.');
                        }
                        record.Route = this.importRouteName;
                        // Lets delete the attributes that don't contain anything useful and will
                        // be filled out when we swap the meter
                        delete record['Outgoing Meter Reading'];
                        delete record['Install Date'];
                        delete record['New Meter Reading'];
                        delete record['New Meter Previous Reading'];
                        flogger.debugall(`Record after conversion: ${JSON.stringify(record)}`);
                    }
                    // Convert the header names according to our conversion string
                    const header = Object.keys(results.data[0]);
                    for (let i = 0; i < header.length; i++) {
                        this.importPreviewHeaders.push({
                            text: header[i],
                            value: header[i],
                            class: 'nowrap',
                        });
                    }
                    this.importPreviewRecords = results.data.slice(0, 3);
                    this.importRecords = results.data;
                }
            }.bind(this);
            reader.readAsText(file);
        },
        uploadImportRecords: function uploadImportRecords() {
            const flogger = this.logger.getLogger('uploadImportRecords');
            flogger.info('Import Records button pressed. Uploading import records ...');
            // TODO Batch in groups of 100
            createMeters(this.importRecords);
            this.showImportWizard = false;
            flogger.debug('Function end.');
        },
        exportData: function exportData() {
            const flogger = this.logger.getLogger('exportData');
            flogger.info(`Exporting data for routes ${JSON.stringify(this.exportSelectedRoutes)} from ${this.dataExportStartDate} to ${this.dataExportEndDate}`);
            const csvData = [];
            for (let i = 0; i < appData.meters.length; i++) {
                const meter = appData.meters[i];
                if (meter['Install Type'] !== this.dataExportInstallType) {
                    continue;
                }
                if (!this.exportSelectedRoutes.includes(meter.Route)) {
                    continue;
                }
                const lastUpdate = new Date(meter.Version);
                if (this.dataExportLimitDates
                        && (lastUpdate < new Date(this.dataExportStartDate)
                            || lastUpdate > new Date(this.dataExportEndDate))) {
                    continue;
                }
                if (meter.Uninstallable && !this.dataExportMeterTypes.includes('Uninstallable')) {
                    continue;
                }
                if (!meter.Uninstallable && !this.dataExportMeterTypes.includes('Installable')) {
                    continue;
                }
                if (meter.Completed && !this.dataExportMeterTypes.includes('Completed')) {
                    continue;
                }
                if (!meter.Completed && !this.dataExportMeterTypes.includes('Incomplete')) {
                    continue;
                }
                const METER_SIZE_DIALS_MAP = {
                    '5/8" x 3/4"': 5,
                    '1"': 5,
                    '1.5"': 5,
                    '2"': 5,
                    '3"': 5,
                    '4"': 4,
                    '6"': 4,
                };
                const METER_SIZE_MULTIPLIER_MAP = {
                    '5/8" x 3/4"': 100,
                    '1"': 100,
                    '1.5"': 1000,
                    '2"': 1000,
                    '3"': 1000,
                    '4"': 1000,
                    '6"': 1000,
                };
                if (this.dataExportInstallType === 'Replace Meter') {
                    csvData.push({
                        'Meter ID': meter['Southern Software Meter ID'],
                        'Meter Mfg': 'Sensus',
                        'Meter Size': meter['Meter Size'],
                        'Meter Units': 'Gallons',
                        'Number Of Dials': METER_SIZE_DIALS_MAP[meter['Meter Size']],
                        'Meter Multiplier': METER_SIZE_MULTIPLIER_MAP[meter['Meter Size']],
                        'Last Read': meter['Outgoing Meter Reading'],
                        'Current Reading': meter['New Meter Reading'],
                        Longitude: meter.Longitude,
                        Latitude: meter.Latitude,
                        'Install Date': meter['Install Date'],
                        'Serial No:': meter['New Meter ID'],
                        'Meter ID No': meter['New Meter ID'],
                        'TransMitter ID': meter['New Meter Transmitter ID'],
                    });
                } else { // this.dataExportInstallType === 'Transmitter Only'
                    csvData.push({
                        'Meter ID': meter['Southern Software Meter ID'],
                        'Meter Size': meter['Meter Size'],
                        Longitude: meter.Longitude,
                        Latitude: meter.Latitude,
                        'Install Date': meter['Install Date'],
                        'TransMitter ID': meter['New Meter Transmitter ID'],
                    });
                }
            }
            if (csvData.length === 0) {
                this.dataExportError = 'No Results Found';
                return;
            }
            // Specify the fields so that we have a header row, even if there is no data
            let fields = [
                'Meter ID', 'Meter Size', 'Longitude', 'Latitude', 'Install Date', 'TransMitter ID',
            ];
            if (this.dataExportInstallType === 'Replace Meter') {
                fields = fields.concat([
                    'Meter Mfg', 'Meter Units', 'Number Of Dials', 'Meter Multiplier', 'Last Read',
                    'Current Reading', 'Serial No:', 'Meter ID No',
                ]);
            }
            const exportFileName = `Hillsville_Data_Export_${new Date().toISOString().replace(':', '.')}.csv`;
            const csvStr = Papa.unparse(csvData, {
                quotes: true,
                columns: fields,
                header: true,
            });
            // saveAs(new Blob(['test string'], {type: "text/plain;charset=utf-8"}),'somefile.txt');
            saveAs(new Blob([csvStr], { type: 'text/csv;charset=utf-8' }), exportFileName);
        },
        clearDeviceCache: function clearDeviceCache() {
            appData.meters = undefined;
            appData.metersById = {};
            appData.metersByRoute = {};
            appData.uploadQueue = new UploadQueue();
            appData.lastUpdate = undefined;
            this.drawer = !this.drawer;
            this.updateLastUpdateStr();
            // updateMeterData();
        },
        updateLastUpdateStr: function updateLastUpdateStr() {
            const flogger = this.logger.getLogger('updateLastUpdateStr');
            flogger.debug('Updating last update string');
            let delayToNextUpdate = 1000 * 60;
            if (!appData.lastUpdate) {
                this.lastUpdateStr = 'Never';
                return;
            }
            const now = Date.now();
            if (now - appData.lastUpdate > 1000 * 60 * 60 * 24) {
                // Its been more than a day
                const daysAgo = Math.floor((now - appData.lastUpdate) / (1000 * 60 * 60 * 24));
                if (daysAgo === 1) {
                    this.lastUpdateStr = '1 day ago';
                } else {
                    this.lastUpdateStr = `${daysAgo} days ago`;
                }
                delayToNextUpdate = 1000 * 60 * 60 * 24; // One Day
            } else if (now - appData.lastUpdate > 1000 * 60 * 60) {
                // Its been more than an hour
                const hoursAgo = Math.floor((now - appData.lastUpdate) / (1000 * 60 * 60));
                if (hoursAgo === 1) {
                    this.lastUpdateStr = '1 hour ago';
                } else {
                    this.lastUpdateStr = `${hoursAgo} hours ago`;
                }
                delayToNextUpdate = 1000 * 60 * 60; // One Hour
            } else if (now - appData.lastUpdate > 1000 * 60) {
                // Its been more than an hour
                const minutesAgo = Math.floor((now - appData.lastUpdate) / (1000 * 60));
                if (minutesAgo === 1) {
                    this.lastUpdateStr = '1 minute ago';
                } else {
                    this.lastUpdateStr = `${minutesAgo} minutes ago`;
                }
            } else {
                this.lastUpdateStr = 'A few seconds ago';
            }
            logger.debug(`Updating last update string to be '${this.lastUpdateStr}'. Next update in ${delayToNextUpdate} ms.`);
            this.updateStrIntervalId = setTimeout(this.updateLastUpdateStr, delayToNextUpdate);
        },
        completedXferStr: function completedXferStr(queuedTransfer) {
            const date = queuedTransfer.completedDate;
            // const date = new Date();
            const dateStr = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
            const timeStr = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
            const completionTime = queuedTransfer.completedDate - queuedTransfer.startedDate;
            let completionStr = `${completionTime}ms`;
            if (completionTime > 1000 && completionTime < 10000) {
                completionStr = `${(completionTime / 1000).toFixed(1)}s`;
            } else if (completionTime > 10000 && completionTime < 60000) {
                completionStr = `${(completionTime / 1000).toFixed(0)}s`;
            } else if (completionTime > 60000) {
                completionStr = `${(completionTime / 60000).toFixed(0)}m`;
            }
            if (queuedTransfer.bytesUploaded === 0) {
                return `Completed ${dateStr} ${timeStr} in ${completionStr}`;
            }
            const speedBytesPerSec = queuedTransfer.bytesUploaded / (completionTime / 1000);
            let speedStr = `${speedBytesPerSec.toFixed(0)} B/s`;
            if (speedBytesPerSec > 1024 * 1024 * 1024) {
                speedStr = `${(speedBytesPerSec / (1024 * 1024 * 1024)).toFixed(1)} GB/s`;
            } else if (speedBytesPerSec > 1024 * 1024) {
                speedStr = `${(speedBytesPerSec / (1024 * 1024)).toFixed(1)} MB/s`;
            } else if (speedBytesPerSec > 1024) {
                speedStr = `${(speedBytesPerSec / 1024).toFixed(1)} KB/s`;
            }
            let size = `${queuedTransfer.bytesUploaded} B`;
            if (queuedTransfer.bytesUploaded > 1024 * 1024) {
                size = `${(queuedTransfer.bytesUploaded / (1024 * 1024)).toFixed(0)} MB`;
            } else if (queuedTransfer.bytesUploaded > 1024) {
                size = `${(queuedTransfer.bytesUploaded / 1024).toFixed(0)} KB`;
            }
            return `Completed ${dateStr} ${timeStr} - ${size} in ${completionStr} at ${speedStr}`;
        },
    },
    created: function created() {
        // this.logger = logger.getLogger('meters');
        this.updateData();
    },
    beforeDestroy: function beforeDestroy() {
        clearInterval(this.updateStrIntervalId);
    },
};
</script>
