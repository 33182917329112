<template>
  <div>
    <app-bar v-on:cacheCleared="updateLastUpdateStr"></app-bar>

    <v-main>

      <div style="padding: 20px">

        <div class="d-flex flex-wrap justify-space-between">
          <div class="flex-grow-1" style="margin-right: 10px">
            <v-btn outlined class="d-sm-none"
                v-on:click="showFullWidthSearch = !showFullWidthSearch">
                <v-icon>mdi-magnify</v-icon>
            </v-btn>
            <v-text-field class="d-none d-sm-flex" v-model="searchValue"
              label="Search" single-line hide-details style="padding-top: 0px; margin-top: 0px"
              id="search-text-field">
            </v-text-field>
          </div>
          <!--div>
            <v-btn outlined class="d-none d-sm-flex"
                  style="margin-right: 30px"
                  v-on:click="updateFilteredMeters">
                <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </div-->
          <div class="d-flex justify-end">
            <v-menu offset-y :close-on-content-click="false">
              <template v-slot:activator="{ on }">
                <v-btn outlined v-on="on" style="margin-right: 10px">
                  <v-icon>mdi-format-list-bulleted</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(col, index) in ALL_COLS"
                  :key="index"
                  @click="toggleColumn(col.text)"
                >
                  <v-list-item-title>
                    <v-icon v-if="showCols.includes(col)" style="margin-right: 32px">
                      mdi-checkbox-marked-outline
                    </v-icon>
                    <v-icon v-else style="margin-right: 32px">
                      mdi-checkbox-blank-outline
                    </v-icon>
                    {{ col.text }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn outlined v-on:click="showFilterBar = !showFilterBar" style="margin-right: 10px">
              <v-icon>mdi-filter-outline</v-icon>
            </v-btn>
            <v-btn outlined v-on:click="updateData()" style="margin-right: 10px">
              <v-icon v-if="!loading">mdi-refresh</v-icon>
              <v-progress-circular v-if="loading" indeterminate size="18" width="2" color="indigo">
              </v-progress-circular>
            </v-btn>
            <v-btn outlined v-on:click="exportData">
              <v-icon>mdi-file-export-outline</v-icon>
            </v-btn>
          </div>
        </div>
        <div v-if="showFullWidthSearch" style="padding-top: 20px"
            class="d-flex flex-wrap justify-space-between">
          <div class="flex-grow-1" style="margin-right: 10px">
            <v-text-field v-model="searchValue" label="Search" id="search-text-field"
              single-line hide-details style="padding-top: 0px; margin-top: 0px">
            </v-text-field>
          </div>
          <!--div>
            <v-btn outlined v-on:click="updateFilteredMeters()">
                <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </div-->
        </div>

        <div style="text-align: right; padding-top: 5px; color: #666">
          Last Updated {{lastUpdateStr}}
        </div>
        <filter-bar v-if="showFilterBar" v-model="filterSpec" :unfiltered-data="allMeters" />
        <div style="font-size: 80%; color: #888">
          {{filteredMeters.length}} of {{allMeters.length}} meters shown
        </div>

        <v-data-table :items="filteredMeters" :headers="showCols"
            :loading="loading" hide-default-footer :mobile-breakpoint="0" disable-pagination
            fixed-header height="100vh" style="margin-top: 20px"
            multi-sort v-bind:sort-by.sync="sortBy" v-bind:sort-desc.sync="sortDesc"
            ref="dataTable">
          <template v-slot:item="props">
            <tr :id="props.item.ID" class="d-block d-sm-none"
                v-if="$vuetify.breakpoint.name === 'xs'" style="cursor: pointer"
                v-on:click="goToMeterDetails(props.item['ID'])">
              <!-- Display for small screens in list format -->
              <td>
                <ul class="flex-content">
                  <li v-for="header in props.headers" :key="header.value" class="flex-item"
                    :data-label="header.value">
                    <span v-if="METER_PROPS_BY_KEY[header.value]
                        && METER_PROPS_BY_KEY[header.value].type === Boolean">
                      <v-icon v-if="props.item[header.value]">mdi-check</v-icon>
                    </span>
                    <span v-else-if="METER_PROPS_BY_KEY[header.value]
                        && METER_PROPS_BY_KEY[header.value].type === PROP_TYPES.ISO_TIMESTAMP">
                      {{new Date(props.item[header.value]).toLocaleString()}}
                    </span>
                    <span v-else>
                      {{ props.item[header.value] }}
                    </span>
                  </li>
                </ul>
              </td>
            </tr>
            <tr :id="props.item.ID" class="d-none d-sm-table-row"
                v-if="$vuetify.breakpoint.name !== 'xs'"
                style="cursor: pointer" v-on:click="goToMeterDetails(props.item['ID'])">
              <!-- Display for larger screens in table format -->
              <td v-for="header in props.headers" :key="header.value"
                  :style="{ 'text-align': header.align }">
                <span v-if="METER_PROPS_BY_KEY[header.value]
                    && METER_PROPS_BY_KEY[header.value].type === Boolean">
                  <v-icon v-if="props.item[header.value]">mdi-check</v-icon>
                </span>
                <span v-else-if="METER_PROPS_BY_KEY[header.value]
                    && METER_PROPS_BY_KEY[header.value].type === PROP_TYPES.ISO_TIMESTAMP">
                  {{new Date(props.item[header.value]).toLocaleString()}}
                </span>
                <span v-else>
                  {{ props.item[header.value] }}
                </span>
              </td>
            </tr>
          </template>
          <template v-slot:no-results>
            No meters found
          </template>
        </v-data-table>
      </div>
    </v-main>

  </div>
</template>

<script>
import papa from 'papaparse';
import { saveAs } from 'file-saver';
import { appData, updateMeterData } from '../meter-data';
import { WINDOW_TITLE } from '../site-consts';
import {
    METER_PROPS, METER_PROPS_BY_KEY, METER_PROPS_BY_NAME, PROP_TYPES,
    METER_LIST_COLS,
} from '../meter-props';
import logger from '../logger';
import appBar from '../components/app-bar.vue';
import filterBar from '../components/filter-bar.vue';
import { filterData, addFilterSpecToUrl, updateFilterSpecFromUrl } from '../data-filters';
import { props as filterProps } from '../filter-props';

const moduleLogger = logger.getLogger('meters');
// moduleLogger.logLevel = moduleLogger.loggerLevels.debug;

export default {
    name: 'view-meters',
    components: {
        appBar: appBar,
        filterBar: filterBar,
    },
    data: function data() {
        const flogger = moduleLogger.getLogger('data');
        flogger.debug('In Vue data() function');
        const filterSpec = [];
        filterProps.forEach((prop) => filterSpec.push(
            { prop: prop, value: prop.defaultValue(prop.choices(appData.meters)) },
        ));
        return {
            searchValue: '',
            loading: false,
            appData: appData,
            showFullWidthSearch: false,
            lastUpdateStr: 'Never',
            updateStrIntervalId: 0,
            showFilterBar: false,
            showCols: [...METER_LIST_COLS],
            filterSpec: filterSpec,
            ALL_COLS: METER_PROPS.sort((a, b) => a.text.localeCompare(b.text)),
            allColsByName: METER_PROPS_BY_NAME,
            sortBy: [],
            sortDesc: [],
            METER_PROPS_BY_KEY: METER_PROPS_BY_KEY,
            PROP_TYPES: PROP_TYPES,
            allMeters: appData.meters,
        };
    },
    methods: {
        toggleColumn: function toggleColumn(colName) {
            const col = this.allColsByName[colName];
            const index = this.showCols.indexOf(col);
            if (index === -1) {
                this.showCols.push(col);
            } else {
                this.showCols.splice(index, 1);
            }
            this.updateUrl();
        },
        goToMeterDetails: function goToMeterDetails(meterId) {
            this.$router.push(`/meters/${meterId}`);
        },
        updateData: async function updateData() {
            const flogger = moduleLogger.getLogger('updateData');
            flogger.info('Update Data button pressed');
            this.loading = true;
            try {
                flogger.debug('Calling updateMeterData() ...');
                await updateMeterData();
                flogger.debug('Call to updateMeterData() complete');
            } catch (err) {
                flogger.error(`There was an unexpected error when calling updateMeterData: ${JSON.stringify(err)}`);
            }
            this.loading = false;
            clearInterval(this.updateStrIntervalId);
            this.updateLastUpdateStr();
        },
        exportData: async function exportData() {
            const headers = this.$refs.dataTable.computedHeaders.map(
                (headerProps) => headerProps.value,
            );
            const exportFileName = `Meters_${new Date().toISOString().replace(':', '.')}.csv`;
            const csvStr = papa.unparse(this.$refs.dataTable.internalCurrentItems, {
                quotes: true,
                columns: headers,
                header: true,
            });
            saveAs(new Blob([csvStr], { type: 'text/csv;charset=utf-8' }), exportFileName);
        },
        updateLastUpdateStr: function updateLastUpdateStr() {
            const flogger = moduleLogger.getLogger('updateLastUpdateStr');
            flogger.debug('Updating last update string');
            let delayToNextUpdate = 1000 * 60;
            if (!appData.lastUpdate) {
                this.lastUpdateStr = 'Never';
                return;
            }
            const now = Date.now();
            if (now - appData.lastUpdate > 1000 * 60 * 60 * 24) {
                // Its been more than a day
                const daysAgo = Math.floor((now - appData.lastUpdate) / (1000 * 60 * 60 * 24));
                if (daysAgo === 1) {
                    this.lastUpdateStr = '1 day ago';
                } else {
                    this.lastUpdateStr = `${daysAgo} days ago`;
                }
                delayToNextUpdate = 1000 * 60 * 60 * 24; // One Day
            } else if (now - appData.lastUpdate > 1000 * 60 * 60) {
                // Its been more than an hour
                const hoursAgo = Math.floor((now - appData.lastUpdate) / (1000 * 60 * 60));
                if (hoursAgo === 1) {
                    this.lastUpdateStr = '1 hour ago';
                } else {
                    this.lastUpdateStr = `${hoursAgo} hours ago`;
                }
                delayToNextUpdate = 1000 * 60 * 60; // One Hour
            } else if (now - appData.lastUpdate > 1000 * 60) {
                // Its been more than an hour
                const minutesAgo = Math.floor((now - appData.lastUpdate) / (1000 * 60));
                if (minutesAgo === 1) {
                    this.lastUpdateStr = '1 minute ago';
                } else {
                    this.lastUpdateStr = `${minutesAgo} minutes ago`;
                }
            } else {
                this.lastUpdateStr = 'A few seconds ago';
            }
            flogger.debug(`Updating last update string to be '${this.lastUpdateStr}'. Next update in ${delayToNextUpdate} ms.`);
            this.updateStrIntervalId = setTimeout(this.updateLastUpdateStr, delayToNextUpdate);
        },
        updateUrl: function updateUrl() {
            const searchParams = new URLSearchParams(document.location.search);
            if (this.searchValue) {
                searchParams.set('search', this.searchValue);
            } else {
                searchParams.delete('search');
            }
            if (JSON.stringify(this.showCols.map((header) => header.text))
                === JSON.stringify(METER_LIST_COLS.map((header) => header.text))) {
                searchParams.delete('columns');
            } else {
                searchParams.set('columns', this.showCols.map((header) => header.text).join(','));
            }
            if (this.sortBy.length > 0) {
                searchParams.set('sortBy', this.sortBy.join(','));
            } else {
                searchParams.delete('sortBy');
            }
            if (this.sortDesc.length > 0) {
                searchParams.set('sortDesc', this.sortDesc.join(','));
            } else {
                searchParams.delete('sortDesc');
            }
            const searchParamsStr = searchParams.toString();
            const searchStr = searchParamsStr.length > 0 ? `?${searchParamsStr}` : '';
            const newUrl = `${document.location.pathname}${searchStr}`;
            window.history.replaceState(null, document.title, newUrl);
        },
    },
    created: function created() {
        document.title = `${WINDOW_TITLE} Meters`;
        this.updateData();
        // See if we have filters or column sorting defined on the URL
        if (this.$route.query.columns) {
            this.showCols = [];
            const colNames = this.$route.query.columns.split(',');
            for (let i = 0; i < colNames.length; i++) {
                if (this.allColsByName[colNames[i]] === undefined) {
                    continue;
                }
                this.showCols.push(this.allColsByName[colNames[i]]);
            }
        }
        if (this.$route.query.search) {
            this.searchValue = this.$route.query.search;
        }
        if (this.$route.query.sortBy) {
            this.sortBy = this.$route.query.sortBy.split(',');
        }
        if (this.$route.query.sortDesc) {
            const vals = this.$route.query.sortDesc.split(',');
            for (let i = 0; i < vals.length; i++) {
                if (vals[i] === 'true') {
                    vals[i] = true;
                } else {
                    vals[i] = false;
                }
            }
            this.sortDesc = vals;
        }
        updateFilterSpecFromUrl(this.filterSpec, this.$route.query);
    },
    computed: {
        filteredMeters: function filteredMeters() {
            // Our searching and filtering flow goes ...
            // Unfiltered List -> Filtered List -> Searched List ->
            // We filter first b/c that should be quicker than the JSON string matching
            const flogger = moduleLogger.getLogger('filteredMeters');
            flogger.logLevel = flogger.loggerLevels.debug;
            flogger.debug('Updating filteredMeters ...');
            let data = filterData(this.allMeters, this.filterSpec);
            if (this.searchValue) {
                flogger.debug(`Searching meters for the term "${this.searchValue}"`);
                const searchStartTime = new Date();
                const searchedData = [];
                const searchValLower = this.searchValue.toLowerCase();
                for (let i = 0; i < data.length; i++) {
                    if (JSON.stringify(data[i]).toLowerCase().includes(searchValLower)) {
                        searchedData.push(data[i]);
                    }
                }
                const searchTime = new Date() - searchStartTime;
                flogger.info(`Took ${searchTime / 1000} seconds to search ${data.length} items down to ${searchedData.length}.`, { searchTime: searchTime });
                data = searchedData;
            }
            return data;
        },
    },
    watch: {
        searchValue: function searchValueChanged() {
            const flogger = moduleLogger.getLogger('searchValueChanged');
            flogger.debug(`The search variable changed to ${this.searchValue}`);
            this.updateUrl();
        },
        sortBy: function sortByChanged() {
            const flogger = moduleLogger.getLogger('sortByChanged');
            flogger.debug(`The sortBy variable changed to ${this.sortBy}`);
            this.updateUrl();
        },
        sortDesc: function sortDescChanged() {
            const flogger = moduleLogger.getLogger('sortDescChanged');
            flogger.debug(`The sortDesc variable changed to ${this.sortDesc}`);
            this.updateUrl();
        },
        filterSpec: {
            deep: true,
            handler: function filterSpecChanged() {
                const flogger = moduleLogger.getLogger('filterSpecChanged');
                // flogger.logLevel = flogger.loggerLevels.debug;
                flogger.debug('Filter spec changed. Calling addFilterSpecToUrl().');
                addFilterSpecToUrl(this.filterSpec);
            },
        },
    },
    activated: function activated() {
        document.title = `${WINDOW_TITLE} Meters`;
    },
    beforeDestroy: function beforeDestroy() {
        clearInterval(this.updateStrIntervalId);
    },
};
</script>
