// This file contains details about the meter properties that this project will have. It was
// originally created to fix a circular dependency that existed between
// site-consts -> logger -> site-consts by removing all imports from site-consts.
import appLogger from './logger';

const mlogger = appLogger.getLogger('site-consts');
// mlogger.logLevel = mlogger.loggerLevels.debug;

// const API_BASE = 'https://vinton-meter-swap.carlsapp.com/api';
// const STORAGE_KEY = 'MeterData';
// const WINDOW_TITLE = 'Vinton Meter Swap';
// const APP_VERSION = '2.8';
const ROUTE_KEY = 'Route Name';
const NUM_DETAILS_SHOW = 3;

const PROP_TYPES = {
    BOOL: Boolean,
    STRING: String,
    NUMBER: Number,
    SELECT: 'Select',
    SELECT_MULTI: 'Select Multiple',
    SELECT_EDITABLE: 'Select Editable',
    BARCODE: 'Barcode',
    TEXTAREA: 'Text Area',
    DATE: 'Date',
    ISO_TIMESTAMP: 'ISO Timestamp',
    INSTALL_TYPE: 'Install Type',
    TIMER_TEXT: 'Timer Text',
    CREW_WITH_HOURS: 'Crew With Hours',
};

// const DEFAULT_IMPORT_FILE_FORMAT = 'Fixed Width';
const FEATURE_TOGGLES = {
    GPS_EDITABLE: true,
    SHOW_GPS: false,
};

// The meter props is a list of all the properties we have for the meters
//   key - The meter property name that contains the value for this property meter[prop.key]
//   type - The type of the prop. Possible values: Boolean, String, Number
//   TODO Complete this list
const METER_PROPS = [
    // The values for these props were provided by Saltville
    { key: 'Account #' },
    { key: 'Full Name' },
    { key: 'Service Address', align: 'left' },
    { key: 'Previous Install Date' },
    { key: 'Current Reading', type: PROP_TYPES.NUMBER },
    { key: 'Current Reading Date' },
    { key: 'Previous Reading', type: PROP_TYPES.NUMBER },
    { key: 'Previous Reading Date' },
    { key: 'Avg Usage Amount', type: PROP_TYPES.NUMBER },
    { key: 'Serial #' },
    { key: 'Meter #' },
    { key: 'Meter Units' },
    { key: 'Multiplier', type: PROP_TYPES.NUMBER },
    { key: 'Number of Users', type: PROP_TYPES.NUMBER },
    { key: 'Number of Dials', type: PROP_TYPES.NUMBER },
    // The values for these props were derived from the values provided by Saltville
    { key: 'Route Name' },
    // And we will collect data for these values
    { key: 'Latitude' },
    { key: 'Longitude' },
    {
        key: 'Work Type',
        modifiable: true,
        type: PROP_TYPES.SELECT,
        choices: [
            'Site Survey',
            'Meter Install',
        ],
        defaultValue: 'Meter Install',
    }, {
        key: 'Parts Needed',
        modifiable: true,
        type: PROP_TYPES.SELECT_MULTI,
        choices: [
            'Lid',
            'Frame',
            'Box',
            'Retro Resetter',
            '7" Resetter',
            '13" Resetter',
            'Sidewinder Resetter',
            'Expansion Adapter',
        ],
        optional: true,
        condition: function condition(meter) {
            return meter['Work Type'] === 'Site Survey';
        },
    }, {
        key: 'Meter Box Shape',
        modifiable: true,
        type: PROP_TYPES.SELECT,
        choices: [
            'Rectangular',
            'Circular',
        ],
        defaultValue: 'Rectangular',
        condition: function condition(meter) {
            return meter['Work Type'] === 'Site Survey';
        },
    }, {
        key: 'Meter Box Type',
        modifiable: true,
        type: PROP_TYPES.SELECT,
        choices: [
            'Concrete',
            'Plastic',
            'Metal',
        ],
        defaultValue: 'Plastic',
        condition: function condition(meter) {
            return meter['Work Type'] === 'Site Survey';
        },
    }, {
        key: 'Leak Present',
        type: PROP_TYPES.BOOL,
        defaultValue: false,
        modifiable: true,
        condition: function condition(meter) {
            return meter['Work Type'] === 'Site Survey';
        },
    }, {
        key: 'Meter Needs to be Cleaned Out',
        type: PROP_TYPES.BOOL,
        defaultValue: false,
        modifiable: true,
        condition: function condition(meter) {
            return meter['Work Type'] === 'Site Survey';
        },
    }, {
        key: 'Digging Needed',
        modifiable: true,
        type: PROP_TYPES.SELECT_MULTI,
        choices: [
            'None',
            'Machine',
            'Hand',
        ],
        defaultValue: ['None'],
        condition: function condition(meter) {
            return meter['Work Type'] === 'Site Survey';
        },
    }, {
        key: 'In Asphalt',
        type: PROP_TYPES.BOOL,
        defaultValue: false,
        modifiable: true,
        condition: function condition(meter) {
            return meter['Work Type'] === 'Site Survey';
        },
    }, {
        key: 'In Concrete',
        type: PROP_TYPES.BOOL,
        defaultValue: false,
        modifiable: true,
        condition: function condition(meter) {
            return meter['Work Type'] === 'Site Survey';
        },
    }, {
        key: 'Yoke',
        modifiable: true,
        type: PROP_TYPES.SELECT,
        choices: [
            'None',
            'Setter',
            'Straight Piped',
        ],
        defaultValue: 'None',
        condition: function condition(meter) {
            return meter['Work Type'] === 'Site Survey';
        },
    }, {
        key: 'Meter Size',
        modifiable: true,
        type: PROP_TYPES.SELECT,
        choices: ['5/8"', '5/8" x 3/4"', '3/4"', '1"', '1.5"', '2"', '3"', '4"'],
        defaultValue: '5/8"',
    }, {
        key: 'Has Check Valve',
        type: PROP_TYPES.BOOL,
        defaultValue: false,
        modifiable: true,
        condition: function condition(meter) {
            return meter['Work Type'] === 'Site Survey';
        },
    }, {
        key: 'Has Cut Off',
        type: PROP_TYPES.BOOL,
        defaultValue: true,
        modifiable: true,
        condition: function condition(meter) {
            return meter['Work Type'] === 'Site Survey';
        },
    }, {
        key: 'Too Deep',
        type: PROP_TYPES.BOOL,
        defaultValue: false,
        modifiable: true,
        condition: function condition(meter) {
            return meter['Work Type'] === 'Site Survey';
        },
    }, {
        key: 'Too Shallow',
        type: PROP_TYPES.BOOL,
        defaultValue: false,
        modifiable: true,
        condition: function condition(meter) {
            return meter['Work Type'] === 'Site Survey';
        },
    }, {
        key: 'Depth From Ground',
        modifiable: true,
        type: PROP_TYPES.NUMBER,
        htmlType: 'number',
        condition: function condition(meter) {
            return meter['Too Deep'] === true || meter['Too Shallow'] === true;
        },
    }, {
        key: 'E-911 Address Incorrect',
        type: PROP_TYPES.BOOL,
        defaultValue: false,
        modifiable: true,
        condition: function condition(meter) {
            return meter['Work Type'] === 'Site Survey';
        },
    }, {
        key: 'Correct Address',
        modifiable: true,
        htmlType: 'number',
        condition: function condition(meter) {
            return meter['E-911 Address Incorrect'] === true;
        },
    },
    // End
    {
    //     key: 'Parts Used',
    //     modifiable: true,
    //     type: PROP_TYPES.SELECT_MULTI,
    //     choices: [
    //         'Lid',
    //         'Frame',
    //         'Box',
    //         'Retro Resetter',
    //         '7" Resetter',
    //         '13" Resetter',
    //         'Sidewinder Resetter',
    //         'Expansion Adapter',
    //     ],
    //     optional: true,
    //     condition: function condition(meter) {
    //         return meter['Work Type'] === 'Meter Install';
    //     },
    // }, {
        key: 'Outgoing Meter Reading',
        modifiable: true,
        type: PROP_TYPES.NUMBER,
        htmlType: 'number',
        validationFunc: function outgoingMeterReadingValid(value, meter) {
            if (!value) {
                return 'A value must be entered.';
            }
            if (value < meter['Current Reading']) {
                return 'The meter reading is less than the last reading of this meter.';
            }
            // if (value > meter['Previous Reading'] + 1000) {
            //     return 'The meter reading shows a consumption of more than 1000 above the ' +
            //          'previous reading. Is it correct?';
            // }
            // The current import file doesn't have a high consumption value, so we don't have a
            // way to check if the value is too high.
            // if (value > this.meter['Last Meter Reading'] + this.meter['High Consumption']) {
            //     return 'This meter consumed more than it has ever previously consumed. Are you
            // sure you read it correctly?';
            // }
            return true;
        },
        condition: function condition(meter) {
            return meter['Work Type'] === undefined || meter['Work Type'] === 'Meter Install';
        },
    }, {
        key: 'New Meter Serial',
        modifiable: true,
        type: PROP_TYPES.BARCODE,
        condition: function condition(meter) {
            return meter['Work Type'] === undefined || meter['Work Type'] === 'Meter Install';
        },
    }, {
    //     key: 'New Meter Reading',
    //     modifiable: true,
    //     type: PROP_TYPES.NUMBER,
    //     defaultValue: 0,
    //     validationFunc: function newMeterReadingValid(value) {
    //         // if (this.meter['Install Type'] !== 'Replace Meter') {
    //         //     return true;
    //         // }
    //         if (value === undefined || value === '') {
    //             return 'A value must be entered.';
    //         }
    //         if (value > 10) {
    //             return `Most readings on new meters are less than 10. Are you sure the value of ${value} is correct?`;
    //         }
    //         return true;
    //     },
    //     htmlType: 'number',
    //     condition: function condition(meter) {
    //         return meter['Work Type'] === 'Meter Install';
    //     },
    // }, {
        key: 'Transmitter ID',
        modifiable: true,
        type: PROP_TYPES.BARCODE,
        condition: function condition(meter) {
            return meter['Work Type'] === undefined || meter['Work Type'] === 'Meter Install';
        },
    }, {
        key: 'Install Date',
        modifiable: true,
        type: PROP_TYPES.DATE,
        defaultValue: function dateDefault() {
            // The date component presents a default value. But, we need this function so the
            // save function can grab a default value for the save.
            const now = new Date();
            return `${now.getMonth() + 1}/${now.getDate()}/${now.getFullYear()}`;
        },
        condition: function condition(meter) {
            return meter['Work Type'] === undefined || meter['Work Type'] === 'Meter Install';
        },
    }, {
        key: 'Town Attention Needed',
        type: PROP_TYPES.BOOL,
        defaultValue: false,
        modifiable: true,
    }, {
        key: 'Uninstallable',
        type: PROP_TYPES.BOOL,
        defaultValue: false,
        modifiable: true,
        condition: function condition(meter) {
            return meter['Work Type'] === undefined || meter['Work Type'] === 'Meter Install';
        },
    }, {
        key: 'Notes',
        modifiable: true,
        type: PROP_TYPES.TEXTAREA,
        optional: true,
    },
    {
        name: 'Last Updated',
        key: 'Version',
        type: PROP_TYPES.ISO_TIMESTAMP,
    },
];

const PHOTOS = [
    {
        name: 'Meter Site',
        shortName: 'survey-site',
        key: 'Photo URL Site Survey Meter Site',
        condition: function condition(meter) {
            return meter['Work Type'] === 'Site Survey';
        },
    }, {
        name: 'Final Read',
        shortName: 'final-read',
        key: 'Photo URL Final Read',
        condition: function condition(meter) {
            return meter['Work Type'] === undefined || meter['Work Type'] === 'Meter Install';
        },
    }, {
        name: 'Meter & Tx Serial',
        shortName: 'post-exchange',
        key: 'Photo URL Post Exchange',
        condition: function condition(meter) {
            return meter['Work Type'] === undefined || meter['Work Type'] === 'Meter Install';
        },
    },
];

// Everything else is pre-computed arrays that can be overriden with custom arrays if needed

const METER_PROPS_BY_KEY = {};
const METER_PROPS_BY_NAME = {};
for (let i = 0; i < METER_PROPS.length; i++) {
    // Apply our default values
    if (METER_PROPS[i].name === undefined) {
        // If we didn't specify a name, the name is the same as the key
        METER_PROPS[i].name = METER_PROPS[i].key;
    }
    if (METER_PROPS[i].type === undefined) {
        // If we didn't specify a type, set as string. This is needed for sorting.
        METER_PROPS[i].type = PROP_TYPES.STRING;
    }
    // Text and value are used by the Vuetify data table header objects
    METER_PROPS[i].text = METER_PROPS[i].name;
    METER_PROPS[i].value = METER_PROPS[i].key;
    if (METER_PROPS[i].modifiable === undefined) {
        METER_PROPS[i].modifiable = false;
    }
    METER_PROPS_BY_KEY[METER_PROPS[i].key] = METER_PROPS[i];
    METER_PROPS_BY_NAME[METER_PROPS[i].name] = METER_PROPS[i];
}

const METER_DETAILS_PROPS = METER_PROPS.filter((prop) => !prop.modifiable);

// const METER_DETAILS_PROPS = [
//     METER_PROPS_BY_KEY['Account #'],
//     METER_PROPS_BY_KEY['Meter #'],
//     METER_PROPS_BY_KEY['Account Type'],
//     METER_PROPS_BY_KEY.Status,
//     METER_PROPS_BY_KEY.Name,
//     METER_PROPS_BY_KEY['Service Address'],
//     METER_PROPS_BY_KEY['Route #'],
//     METER_PROPS_BY_KEY['Sequence #'],
//     METER_PROPS_BY_KEY.Multiplier,
//     METER_PROPS_BY_KEY['Previous Reading'],
//     METER_PROPS_BY_KEY['Outgoing Meter Serial #'],
//     METER_PROPS_BY_KEY['Meter Size'],
//     METER_PROPS_BY_KEY.Version,
// ];

const METER_EDIT_PROPS = METER_PROPS.filter((prop) => prop.modifiable);

// const METER_EDIT_PROPS = [
//     METER_PROPS_BY_KEY['Crew Members'],
//     // METER_PROPS_BY_KEY['Install Type'],
//     // METER_PROPS_BY_KEY['Install Hours'],
//     METER_PROPS_BY_KEY['Parts Needed'],
//     METER_PROPS_BY_KEY['Parts Used'],
//     METER_PROPS_BY_KEY['Need to Schedule Water Outage'],
//     METER_PROPS_BY_KEY['Outgoing Meter Reading'],
//     METER_PROPS_BY_KEY['New Meter Size'],
//     METER_PROPS_BY_KEY['New Meter Serial'],
//     METER_PROPS_BY_KEY['New Meter Reading'],
//     METER_PROPS_BY_KEY['Transmitter ID'],
//     METER_PROPS_BY_KEY['Install Date'],
//     METER_PROPS_BY_KEY['Town Attention Needed'],
//     METER_PROPS_BY_KEY.Notes,
// ];

const INITIAL_SORT_PROPS = [
    METER_PROPS_BY_KEY['Route Name'],
    METER_PROPS_BY_KEY['Account #'],
];

const METER_LIST_COLS = [
    METER_PROPS_BY_KEY['Route Name'],
    METER_PROPS_BY_KEY['Account #'],
    METER_PROPS_BY_KEY['Meter Size'],
    METER_PROPS_BY_KEY['Service Address'],
];
const PHOTOS_BY_TYPE = {};
for (let i = 0; i < PHOTOS.length; i++) {
    PHOTOS_BY_TYPE[PHOTOS[i].shortName] = PHOTOS[i];
}

// To use this file in a CommonJS environment replace export with module.exports =.
export {
    ROUTE_KEY, PROP_TYPES,
    FEATURE_TOGGLES, METER_PROPS, METER_PROPS_BY_KEY,
    METER_PROPS_BY_NAME, METER_DETAILS_PROPS, NUM_DETAILS_SHOW, METER_EDIT_PROPS,
    INITIAL_SORT_PROPS, METER_LIST_COLS, PHOTOS, PHOTOS_BY_TYPE,
};
