<template>
  <div>
    <v-dialog v-model="open">
      <v-card>
        <v-card-title>
          Uploads
          <div class="flex-grow-1"></div>
          <v-btn icon v-on:click.stop="open = false">
          <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div style="color: #555; border-bottom: 1px solid #DDD; margin: 20px 0 5px 0">
            In-Progress
          </div>
          <div v-for="(queuedTransfer, index) in queue.inProgress" style="margin: 8px 0 2px"
              :key="index">
            <div>
              {{queuedTransfer.name}} &nbsp;
              <span style="color: #888">
                {{queuedTransfer.percentUploaded}}% Completed.
                Attempt {{queuedTransfer.attempts}} of
                {{queuedTransfer.maxRetries === -1 ? 'Unlimited' : queuedTransfer.maxRetries}}
              </span>
              <v-btn icon v-on:click.stop="queuedTransfer.maxRetries = 1">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
            <v-progress-linear v-bind:value="queuedTransfer.percentUploaded"></v-progress-linear>
          </div>

          <div style="color: #555; border-bottom: 1px solid #DDD; margin: 20px 0 5px 0">
            Queued
          </div>
          <div v-for="(queuedTransfer, index) in queue.queue" style="margin: 8px 0 2px"
              :key="index">
            <div>
              {{queuedTransfer.name}} &nbsp; <span style="color: #888">Not Started Yet</span>
            </div>
            <v-progress-linear v-bind:value="0"></v-progress-linear>
          </div>

          <div style="color: #555; border-bottom: 1px solid #DDD; margin: 20px 0 5px 0">
            Completed
          </div>
          <div v-for="(queuedTransfer, index) in queue.completed"
              style="margin: 8px 0 2px; display: flex" :key="index">
          <div style="align-self: center">
            <v-btn icon v-on:click.stop="uploadToDetail = queuedTransfer">
              <v-icon v-if="queuedTransfer.result.statusCode === -1
                  || (queuedTransfer.result.statusCode >= 200
                  && queuedTransfer.result.statusCode < 300)">
                mdi-information
              </v-icon>
              <v-icon style="color: red" v-else>mdi-alert</v-icon>
            </v-btn>
          </div>
          <div style="flex-grow: 1">
              <div v-if="queuedTransfer.name">{{queuedTransfer.name}}</div>
              <div v-else>Unnamed Transfer</div>
              <div style="color: #888">{{completedXferStr(queuedTransfer)}}</div>
          </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-if="uploadToDetail" :value="uploadToDetail">
      <v-card>
        <v-card-title>
          Upload<span v-if="uploadToDetail.name"> - {{uploadToDetail.name}}</span>
          <div class="flex-grow-1"></div>
          <v-btn icon v-on:click.stop="uploadToDetail = undefined">
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text></v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import UploadQueue from '../UploadQueue';

export default {
    name: 'upload-queue-dialog',
    props: {
        value: Boolean,
        queue: UploadQueue,
    },
    computed: {
        open: {
            get: function get() {
                return this.value;
            },
            set: function set(value) {
                this.$emit('input', value);
            },
        },
    },
    data: function data() {
        return {
            uploadToDetail: undefined,
        };
    },
    methods: {
        completedXferStr: function completedXferStr(queuedTransfer) {
            const date = queuedTransfer.completedDate;
            const dateStr = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
            const timeStr = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
            const completionTime = queuedTransfer.completedDate - queuedTransfer.startedDate;
            let completionStr = `${completionTime}ms`;
            if (completionTime > 1000 && completionTime < 10000) {
                completionStr = `${(completionTime / 1000).toFixed(1)}s`;
            } else if (completionTime > 10000 && completionTime < 60000) {
                completionStr = `${(completionTime / 1000).toFixed(0)}s`;
            } else if (completionTime > 60000) {
                completionStr = `${(completionTime / 60000).toFixed(0)}m`;
            }
            // if (queuedTransfer.bytesUploaded === 0) {
            //     return `Completed ${dateStr} ${timeStr} in ${completionStr}`;
            // }
            let size = queuedTransfer.totalBytesToUpload;
            let speedStr = '';
            if (queuedTransfer.bytesUploaded !== 0) {
                const speedBytesPerSec = queuedTransfer.bytesUploaded / (completionTime / 1000);
                speedStr = `${speedBytesPerSec.toFixed(0)} B/s`;
                if (speedBytesPerSec > 1024 * 1024 * 1024) {
                    speedStr = `${(speedBytesPerSec / (1024 * 1024 * 1024)).toFixed(1)} GB/s`;
                } else if (speedBytesPerSec > 1024 * 1024) {
                    speedStr = `${(speedBytesPerSec / (1024 * 1024)).toFixed(1)} MB/s`;
                } else if (speedBytesPerSec > 1024) {
                    speedStr = `${(speedBytesPerSec / 1024).toFixed(1)} KB/s`;
                }
                size = `${queuedTransfer.bytesUploaded} B`;
                if (queuedTransfer.bytesUploaded > 1024 * 1024) {
                    size = `${(queuedTransfer.bytesUploaded / (1024 * 1024)).toFixed(0)} MB`;
                } else if (queuedTransfer.bytesUploaded > 1024) {
                    size = `${(queuedTransfer.bytesUploaded / 1024).toFixed(0)} KB`;
                }
                speedStr = ` at ${speedStr}`;
            }
            let { statusCode } = queuedTransfer.result;
            if (statusCode === -1) {
                statusCode = 'Unknown';
            }
            if (queuedTransfer.result.statusCode < 200 || queuedTransfer.result.statusCode >= 300) {
                let resultStr = 'Failed';
                if (queuedTransfer.result.statusCode === -1) {
                    resultStr = 'Unknown Result';
                }
                return `${resultStr} at ${dateStr} ${timeStr}. ${queuedTransfer.bytesUploaded} B of ${queuedTransfer.totalBytesToUpload} B uploaded. Attempted ${queuedTransfer.attempts} times. Status Code: ${statusCode}.`;
            }
            return `Completed at ${dateStr} ${timeStr} - ${size} in ${completionStr}${speedStr}. Status Code: ${statusCode}.`;
        },
    },
};
</script>
