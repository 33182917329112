<template>
  <v-menu v-model="showDatePicker"
      :close-on-content-click="false"
      transition="scale-transition" offset-y min-width="290px"
      :disabled="disabled">
    <template v-slot:activator="{ on }">
      <v-text-field
        v-model="textFieldDate" readonly
        :label="label"
        v-on="on" :disabled="disabled"></v-text-field>
    </template>
    <v-date-picker
      v-model="datePickerFormattedDate"
      @input="showDatePicker = false">
    </v-date-picker>
  </v-menu>
</template>

<script>

// TODO Use an install date format string from site-consts to define what the text looks like

export default {
    name: 'date-text-field',
    // components: {
    //     barcodeScanner: barcodeScanner,
    // },
    props: {
        value: String,
        label: String,
        disabled: Boolean,
    },
    data: function data() {
        return {
            showDatePicker: false,
        };
    },
    computed: {
        datePickerFormattedDate: {
            // The v-date-picker component expects the date to be in the format YYYY-MM-DD
            get: function get() {
                // If our v-model value is undefined, return null to use today's date
                if (!this.value) {
                    return null;
                }
                const [month, day, year] = this.value.split('/');
                return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
            },
            // The v-date-picker component returns the date in the format YYYY-MM-DD
            set: function set(value) {
                // This value should only get set by the v-date-picker. It'll use the format
                // YYYY-MM-DD. Lets copy it over to our v-model value in our format.
                if (!value) {
                    return;
                }
                const [year, month, day] = value.split('-');
                // Use parseInt to strip off the leading 0
                this.$emit('input', `${parseInt(month, 10)}/${parseInt(day, 10)}/${year}`);
            },
        },
        textFieldDate: {
            get: function get() {
                if (!this.value) {
                    const now = new Date();
                    return `${now.getMonth() + 1}/${now.getDate()}/${now.getFullYear()}`;
                }
                return this.value;
            },
        },
    },
};
</script>
