<!--
This component is used to select crew members and assign hours

Attributes:
  crew - An array of crew member names to choose frome
  v-model - An array of [[Name String, Hours Number]]
-->
<template>
  <div style="padding: 20px 0 20px 0">
    <div style="font-weight: bold">Crew Members &amp; Hours</div>
    <table style="padding-left: 20px">
      <tr v-for="(crewMember, index) in defaultedValue" :key="index">
        <!-- Each crew member is a 2 item array. The first item is name. 2nd is hours. -->
        <td style="padding-right: 30px">{{crewMember.Name}}</td>
        <td><v-select v-model="crewMember.Hours"
            style="padding-top: 0px; margin-top: 0px; margin-bottom: -8px;
                width: 100px; display: inline-block;"
            :items="[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"></v-select></td>
        <td style="padding-left: 10px">
            <v-icon v-on:click="removeCrew(index, crewMember.Name)">mdi-delete</v-icon>
            <!--v-btn v-on:click="removeCrew(crewMember)">
                <v-icon v-on:click="removeCrew(crewMember)">mdi-delete</v-icon>
            </v-btn-->
        </td>
      </tr>
      <tr v-if="availableCrew.length > 0">
        <td colspan="2">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined v-bind="attrs" v-on="on">
                Add Crew Members
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-for="(item, index) in availableCrew" :key="index"
                    v-on:click="crewSelected(item)">
                  <v-list-item-title>{{ item }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { appData } from '../meter-data';
import appLogger from '../logger';

const moduleLogger = appLogger.getLogger('crew-with-hours');
// moduleLogger.logLevel = moduleLogger.loggerLevels.debugall;

export default {
    name: 'crew-with-hours',
    props: {
        value: Array,
        crew: Array,
        default: [Array, Function],
    },
    data: function data() {
        return {
            selectedCrew: undefined,
        };
    },
    methods: {
        crewSelected: function crewSelected(crewMemberName) {
            const flogger = moduleLogger.getLogger('crewSelected');
            flogger.info(`Crew member "${crewMemberName}" selected`);
            const prevVal = this.defaultedValue;
            prevVal.push({
                Name: crewMemberName,
                Hours: 0,
            });
            this.$emit('input', prevVal);
            flogger.debug('Crew member added');
        },
        removeCrew: function removeCrew(index, crewMemberName) {
            const flogger = moduleLogger.getLogger('removeCrew');
            flogger.info(`Removing ${crewMemberName} from crew`);
            this.defaultedValue.splice(index, 1);
            flogger.debug('Crew member removed');
        },
        normalizeCrew: function normalizeCrew(crew) {
            // This function is here to deal with the fact that we used to just have a list of crew
            // members. Now, its a list of crew members with hours.
            const flogger = moduleLogger.getLogger('normalizeCrew');
            flogger.debug('Normalizing crew members');
            for (let i = 0; i < crew.length; i++) {
                if (Array.isArray(crew[i])) {
                    // If the crew member is an array, its going to be an array of [ Name, Hours ]
                    crew[i] = {
                        Name: crew[i][0],
                        Hours: crew[i][1],
                    };
                } else if (crew[i].Name === undefined) {
                    // If we got to this point, its probably just a crew member's name. Lets give
                    // them an hours of 0.
                    crew[i] = {
                        Name: crew[i],
                        Hours: 0,
                    };
                }
            }
            flogger.debug('Done normalizing crew');
            return crew;
        },
    },
    computed: {
        availableCrew: {
            get: function get() {
                // Don't include any crew members that were already selected
                const flogger = moduleLogger.getLogger('availableCrew');
                flogger.debug('Getting list of crew members available to add');
                const selectedCrew = this.defaultedValue;
                if (!Array.isArray(selectedCrew)) {
                    return this.crew;
                }
                const availCrew = [];
                for (let i = 0; i < this.crew.length; i++) {
                    let skip = false;
                    for (let j = 0; j < selectedCrew.length; j++) {
                        // console.log(`Comparing ${selectedCrew[j].Name} to ${this.crew[i]}`);
                        if (selectedCrew[j].Name === this.crew[i]) {
                            // console.log('skipping');
                            skip = true;
                        }
                    }
                    if (skip) {
                        continue;
                    }
                    availCrew.push(this.crew[i]);
                }
                return availCrew;
            },
        },
        defaultedValue: {
            get: function get() {
                const flogger = moduleLogger.getLogger('defaultedValueGet');
                flogger.debug('Getting our defaulted value for crew members');
                // If our v-model value is undefined, return null to use today's date
                if (this.value === undefined) {
                    if (typeof this.default === 'function') {
                        flogger.debug('Our value was undefined and our default prop is a function. Calling the function to get our default value.');
                        return this.normalizeCrew(this.default(appData.lastUsedSettings));
                    }
                    flogger.debug('Our value was undefined and our default prop is not a function. Returning the value in our default prop.');
                    return this.normalizeCrew(this.default);
                }
                flogger.debug('We have a value. Ignoring our default prop.');
                return this.normalizeCrew(this.value);
            },
            set: function set(value) {
                const flogger = moduleLogger.getLogger('defaultedValueSet');
                flogger.debug('Our crew members list has changed. Emitting change.');
                flogger.debugall(`The value has changed to ${JSON.stringify(value)}`);
                this.$emit('input', value);
                flogger.debug('Emit done.');
            },
        },
    },
};
</script>
