<template>
  <v-dialog v-model="open">
    <v-card>
      <v-card-title>
        Import Route
        <div class="flex-grow-1"></div>
        <v-btn icon v-on:click.stop="open = false">
          <v-icon>mdi-close-circle-outline</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-text-field label="Route Name" v-model="importRouteName"></v-text-field>
        <v-select v-model="importFormat" :items="['CSV', 'Fixed Width']" label="File Format"
          :append-icon="'mdi-file-question-outline'"></v-select>
        <div v-if="importFormat === 'Fixed Width'">
          <v-text-field label="Format String" v-model="importFormatString"></v-text-field>
        </div>
        <div v-if="importFormat === 'CSV'">
          <v-text-field label="Conversion String" v-model="importCSVConversionString"/>
        </div>
        <v-checkbox label="Limit the number of records imported" v-model="limitNumberImported">
        </v-checkbox>
        <v-text-field v-if="limitNumberImported" label="Import Limit" v-model="importLimit">
        </v-text-field>
        <v-file-input @change="processImportFile" truncate-length="50"></v-file-input>
        <div v-if="importPreviewRecords.length > 0"
            style="width: 100%; margin: auto; overflow-x: scroll; padding-bottom: 20px">
          <div style="font-weight: bold; padding-bottom: 10px">
            Data Preview:
          </div>
          <div style="width: 100%; max-height: 300px; overflow: scroll">
            <v-data-table id="import-preview" :items="importPreviewRecords" disable-pagination
                :headers="importPreviewHeaders" hide-default-footer dense disable-sort/>
          </div>
          <div style="padding-top: 10px">Total Records to Import: {{importRecords.length}}</div>
        </div>
        <v-btn v-if="importPreviewRecords.length > 0" block dark color="indigo"
            @click="uploadImportRecords">
          Import Records
        </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Papa from 'papaparse';
import { createMeters } from '../meter-data';
import appLogger from '../logger';

const moduleLogger = appLogger.getLogger('import-dialog');

export default {
    name: 'import-dialog',
    props: {
        value: Boolean,
    },
    computed: {
        open: {
            get: function get() {
                return this.value;
            },
            set: function set(value) {
                this.$emit('input', value);
            },
        },
    },
    data: function data() {
        return {
            importFormat: 'Fixed Width',
            // TODO Watch import route name and update import records to have the correct route
            importRouteName: '',
            importRecords: [],
            importPreviewHeaders: [],
            importPreviewRecords: [],
            limitNumberImported: false,
            importLimit: 1,
            importFormatString: '{Cycle:n:2}'
                              + '{Route Number:n:2}'
                              + '{Read/Walk Sequence:n:5}'
                              + '{Service Description:s:20}'
                              + '{SDP/Socket ID:s:14}'
                              + '{Customer Account (CSID+LCID):s:19}'
                              + '{Customer ID:s:9}'
                              + '{Location/Premise ID:s:9}'
                              + '{Old Meter Number:s:12}'
                              + '{Install Date:s:10}'
                              + '{Register or Body Serial Number:s:12}'
                              + '{High Consumption:s:12}'
                              + '{Low Consumption:s:12}'
                              + '{Rate Class / Account Type:s:25}'
                              + '{Customer Phone:s:13}'
                              + '{Customer Name:s:30}'
                              + '{Service Address:s:48}'
                              + '{City:s:30}'
                              + '{State:s:2}'
                              + '{Zip:s:10}'
                              + '{Account Status:s:25}'
                              + '{Mailing Address 1:s:48}'
                              + '{Mailing Address 2:s:48}'
                              + '{Mailing City:s:30}'
                              + '{Mailing State:s:2}'
                              + '{Mailing Zip Code:s:9}'
                              + '{Register Name / Read Units:s:4}'
                              + '{Previous Read:s:13}'
                              + '{Previous Reading Date:s:10}'
                              + '{Old Meter Radio ID:s:12}'
                              + '{Touch Read Register Number:s:12}'
                              + '{Old Meter Size Code:s:4}'
                              + '{Old Meter Size Description:s:25}'
                              + '{Old Meter Make:s:25}'
                              + '{Meter Location:s:25}'
                              + '{Specific Meter Location:s:25}'
                              + '{Additional Meter Location Info:s:200}'
                              + '{Latitude:s:16}'
                              + '{Longitude:s:16}',
            importCSVConversionString: '{Meter ID:s:Outgoing Meter ID}'
                                     + '{Account Number:s:Account Number}'
                                     + '{Name:s:Customer Name}'
                                     + '{ServiceAddr:s:Service Address}'
                                     + '{Meter Type:s:Outgoing Meter Type}'
                                     + '{Meter Mfg:s:Outgoing Meter Manufacturer}'
                                     + '{Meter Size:s:Outgoing Meter Size}'
                                     + '{Meter Units:s:Outgoing Meter Units}'
                                     + '{Number Of Dials:s:Number Of Dials on Outgoing Meter}'
                                     + '{Meter Multiplier:n:Outgoing Meter Multiplier}'
                                     + '{Last Curr Read:n:Outgoing Meter Previous Reading}'
                                     + '{Last Read:n:Outgoing Meter Reading}'
                                     + '{Current Reading:n:New Meter Reading}'
                                     + '{Previous Reading:n:New Meter Previous Reading}'
                                     + '{Serial No::s:Outgoing Meter Serial}'
                                     // We've already captured the outgoing meter ID with the first
                                     // field. I see no point in capturing it again.
                                     // + '{Meter ID No:s:Outgoing Meter ID}'
                                     + '{TransMitter ID:s:Outgoing Meter Transmitter ID}'
                                     // TODO Check if we will be getting any latitude longitude data
                                     + '{Longitude:n:Longitude}'
                                     + '{Latitude:n:Latitude}'
                                     + '{Install Date:s:Install Date}',
        };
    },
    methods: {
        processImportFile: function processImportFile(file) {
            const flogger = moduleLogger.getLogger('processImportFile');
            flogger.logLevel = flogger.loggerLevels.debug;
            flogger.info('Processing the file to import.');
            // Process the importFormatString
            const reader = new FileReader();
            this.importRecords = [];
            this.importPreviewHeaders = [];
            this.importPreviewRecords = [];
            reader.onload = function onload(e) {
                const fileContents = e.target.result;
                flogger.info(`Import Format: ${this.importFormat}`);
                flogger.info(`Setting route to ${this.importRouteName}`);
                if (this.importFormat === 'Fixed Width') {
                    const lines = fileContents.split(/\r\n|\n|\r/);
                    flogger.info(`Import Format String: ${this.importFormatString}`);
                    const formatFields = [...this.importFormatString.matchAll(/\{([^:]*):([sSnN]):([0-9]*)\}/g)];
                    for (let i = 0; i < formatFields.length; i++) {
                        this.importPreviewHeaders.push({
                            text: formatFields[i][1],
                            value: formatFields[i][1],
                            class: 'nowrap',
                        });
                    }
                    for (let i = 0; i < lines.length; i++) {
                        if (this.limitNumberImported && i >= this.importLimit) {
                            // If we're limiting the number of records to import
                            break;
                        }
                        const record = {};
                        let charsRead = 0;
                        for (let j = 0; j < formatFields.length; j++) {
                            const fieldLen = parseInt(formatFields[j][3], 10);
                            if (i === 0) {
                                flogger.debug(`Reading field ${formatFields[j][1]} in character positions ${charsRead}-${fieldLen}`);
                            }
                            // TODO Check that its a number
                            let value = lines[i].substr(charsRead, fieldLen).trim();
                            if (formatFields[j][2].toLowerCase() === 'n') {
                                value = parseFloat(value);
                                // TODO Check that its a number
                            }
                            record[formatFields[j][1]] = value;
                            charsRead += fieldLen;
                        }
                        record.Route = this.importRouteName;
                        this.importRecords.push(record);
                        this.importPreviewRecords.push(record);
                    }
                    console.log(this.importRecords[0]);
                } else if (this.importFormat === 'CSV') {
                    // const conversions = [...this.importCSVConversionString.matchAll(
                    //     /\{(.*):([sSnN]):(.*)\}/g)]
                    flogger.info(`CSV Conversion String: ${this.importCSVConversionString}`);
                    const conversions = [...this.importCSVConversionString.matchAll(/\{(.*?)\}/g)];
                    flogger.debug(`Conversion list: ${JSON.stringify(conversions)}`);
                    // The conversion map has keys of the CSV mapped to an object that tells us
                    // what the new field name should be and what type of variable it is.
                    const conversionMap = {};
                    for (let i = 0; i < conversions.length; i++) {
                        const convFields = conversions[i][1].match(/(.*):([sSnN]):(.*)/);
                        conversionMap[convFields[1]] = {
                            type: convFields[2],
                            newFieldName: convFields[3],
                        };
                    }
                    flogger.debug(`Conversion map: ${JSON.stringify(conversionMap)}`);
                    flogger.debug('Parsing CSV file with papaparse ...');
                    const results = Papa.parse(fileContents, {
                        header: true,
                    });
                    flogger.debug('Papa parse complete.');
                    flogger.debugall(`Papa parse results: ${JSON.stringify(results)}`);
                    // Convert our field names
                    const fields = Object.keys(conversionMap);
                    for (let i = 0; i < results.data.length; i++) {
                        const record = results.data[i];
                        flogger.debugall(`Converting record ${JSON.stringify(record)}`);
                        // TODO Optimize this to only look at the keys we care about
                        for (let j = 0; j < fields.length; j++) {
                            const fieldName = fields[j];
                            flogger.debugall(`Converting field ${fieldName}`);
                            if (!record[fieldName]) {
                                // If the field doesn't exist in our conversionMap, skip it
                                flogger.debugall('Field does not exist in the record. Skipping.');
                                continue;
                            }
                            const conversionProps = conversionMap[fieldName];
                            flogger.debugall(`Conversion props: ${JSON.stringify(conversionProps)}`);
                            if (conversionProps.type.toLowerCase() === 'n') {
                                flogger.debugall('Conversion type is number. Converting to float.');
                                record[fieldName] = parseFloat(record[fieldName]);
                            }
                            if (fieldName !== conversionProps.newFieldName) {
                                // The field name needs to be changed
                                record[conversionProps.newFieldName] = record[fieldName];
                                delete record[fieldName];
                            }
                            flogger.debugall('Conversion complete for this field.');
                        }
                        record.Route = this.importRouteName;
                        // Lets delete the attributes that don't contain anything useful and will
                        // be filled out when we swap the meter
                        delete record['Outgoing Meter Reading'];
                        delete record['Install Date'];
                        delete record['New Meter Reading'];
                        delete record['New Meter Previous Reading'];
                        flogger.debugall(`Record after conversion: ${JSON.stringify(record)}`);
                    }
                    // Convert the header names according to our conversion string
                    const header = Object.keys(results.data[0]);
                    for (let i = 0; i < header.length; i++) {
                        this.importPreviewHeaders.push({
                            text: header[i],
                            value: header[i],
                            class: 'nowrap',
                        });
                    }
                    this.importPreviewRecords = results.data.slice(0, 3);
                    this.importRecords = results.data;
                }
            }.bind(this);
            reader.readAsText(file);
        },
        uploadImportRecords: function uploadImportRecords() {
            const flogger = moduleLogger.getLogger('uploadImportRecords');
            flogger.info('Import Records button pressed. Uploading import records ...');
            // TODO Batch in groups of 100
            createMeters(this.importRecords);
            this.open = false;
            flogger.debug('Function end.');
        },
    },
};
</script>
