import LocalStorageObj from './LocalStorageObj';

// Lets store our settings object in local storage
export default new LocalStorageObj('Settings', {
    defaultObj: {
        // Our Default Settings
        autoSaveOnType: true,
        checkChangesOnType: true,
    },
});
