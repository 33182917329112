<template>
  <div style="display: flex">
    <span style="align-self: center; padding-right: 20px">{{label}}:</span>
    <v-text-field v-model="xValue" type="number" style="flex-grow: 1"></v-text-field>
    <span style="align-self: center; padding: 0 10px"> x </span>
    <v-text-field v-model="yValue" type="number" style="flex-grow: 1"></v-text-field>
    <span style="align-self: center; padding: 0 10px">ft</span>
  </div>
</template>

<script>
export default {
    name: 'dimension-field',
    props: {
        label: String,
        value: String,
    },
    methods: {
        parseInput: function parseInput() {
            if (this.value === undefined) {
                return [undefined, undefined];
            }
            const inputSplit = this.value.split('x');
            if (inputSplit.length !== 2) {
                // We have a weird string. Log an error and return undefined
                console.log(`Our input (${this.value}) is a malformed dimension. Expected "NNNxNNN".`);
                return [undefined, undefined];
            }
            return inputSplit.map((numString) => Number(numString));
        },
    },
    computed: {
        xValue: {
            get: function get() {
                return this.parseInput()[0];
            },
            set: function set(newValue) {
                const dims = this.parseInput().map((val) => {
                    if (val === undefined) {
                        return '';
                    }
                    return val;
                });
                this.$emit('input', `${newValue}x${dims[1]}`);
            },
        },
        yValue: {
            get: function get() {
                return this.parseInput()[1];
            },
            set: function set(newValue) {
                const dims = this.parseInput().map((val) => {
                    if (val === undefined) {
                        return '';
                    }
                    return val;
                });
                this.$emit('input', `${dims[0]}x${newValue}`);
            },
        },
    },
};
</script>
