import LogMessage from './LogMessage';

const DEFAULT_LOGGER_LEVELS = {
    critical: 50,
    error: 40,
    warning: 30,
    info: 20,
    debug: 10,
    debugall: 0,
};

export default class Logger {
    constructor(name, parent, loggerLevels = DEFAULT_LOGGER_LEVELS, logSelf = true) {
        this.name = name;
        this.handlers = [];
        this.parent = parent;
        this.children = {};
        this.logLevel = undefined;
        this.loggerLevels = loggerLevels;
        // for (const [levelName, levelValue] of Object.entries(loggerLevels)) {
        const levelNames = Object.keys(loggerLevels);
        for (let i = 0; i < levelNames.length; i++) {
            const levelName = levelNames[i];
            const levelValue = loggerLevels[levelName];
            this[levelName] = function logMsg(msg, props) {
                this.log(levelValue, msg, props);
            };
        }
        if (logSelf) {
            this._logger = this.createLogger('logger', DEFAULT_LOGGER_LEVELS, false);
        }
        if (!parent) {
            this.setLogLevelsFromEnvVariables();
        }
    }

    get fullName() {
        if (this.parent) {
            return `${this.parent.fullName}.${this.name}`;
        }
        return this.name;
    }

    createLogger(name, loggerLevels = this.loggerLevels, logSelf = true) {
        this.children[name] = new Logger(name, this, loggerLevels, logSelf);
        return this.children[name];
    }

    getLogger(name) {
        // The parameter name is a string and can be a series of names separated by a period (.).
        const nameSplit = name.split('.');
        let child = this.children[nameSplit[0]];
        if (!child) {
            child = this.createLogger(nameSplit[0]);
        }
        if (nameSplit.length > 1) {
            return child.getLogger(nameSplit.slice(1).join('.'));
        }
        return child;
    }

    use(handler) {
        this.handlers.push(handler);
    }

    get effectiveLogLevel() {
        if (this.logLevel === undefined && this.parent) {
            return this.parent.effectiveLogLevel;
        }
        return this.logLevel;
    }

    async _callHandlers(logMsg) {
        for (let i = 0; i < this.handlers.length; i++) {
            const resp = this.handlers[i](logMsg);
            if (resp instanceof Promise) {
                // eslint-disable-line
                await resp;  // eslint-disable-line
            }
        }
        if (this.parent) {
            const resp = this.parent._callHandlers(logMsg);
            if (resp instanceof Promise) {
                await resp;
            }
        }
    }

    log(level, msg, props) {
        let logMsg = level;
        if (!(logMsg instanceof LogMessage)) {
            logMsg = new LogMessage(this, level, msg, props);
        }
        // Our effective log level is determined by going up through our parent loggers until
        // we find a logger with a log level set. We then use that log level to determine if we
        // should be doing anything.
        const loggerLevel = this.effectiveLogLevel;
        if (loggerLevel === undefined || loggerLevel > logMsg.level) {
            // If no logger level is set on any of our loggers or its set higher than this message's
            // level, lets not do anything.
            return;
        }
        this._callHandlers(logMsg);
    }

    setLogLevelsFromEnvVariables() {
        // TODO Set our own log level based on LOG_LEVEL
        let logLevels;
        if (typeof process !== 'undefined' && process.env && process.env.LOGGER_LEVELS) {
            // If we are running in NodeJS and we have the LOGGER_LEVELS environment variable set
            if (this._logger) {
                this._logger.info(
                    'Environment variable LOGGER_LEVELS found. Setting log levels ...',
                );
            }
            logLevels = JSON.parse(process.env.LOGGER_LEVELS);
        } else if (typeof window !== 'undefined' && window.LOGGER_LEVELS) {
            if (this._logger) {
                this._logger.info('window.LOGGER_LEVELS found. Setting log levels ...');
            }
            logLevels = window.LOGGER_LEVELS;
        } else {
            // We didn't find an environment variable or window.LOGGER_LEVELS set
            if (this._logger) {
                this._logger.debug('No LOGGER_LEVELS value found in an environment variable or attached to window.');
            }
            return;
        }
        if (this._logger) {
            this._logger.debug(`LOGGER_LEVELS=${process.env.LOGGER_LEVELS}`);
        }
        const loggers = Object.keys(logLevels);
        for (let i = 0; i < loggers.length; i++) {
            let logLevel = logLevels[loggers[i]];
            if (this._logger) {
                this._logger.info(`Setting logger ${loggers[i]} log level to ${logLevel}`);
            }
            // eslint-disable-next-line no-restricted-globals
            if (isNaN(logLevel)) {
                // Check to see if the user passed the name of the level
                logLevel = this.loggerLevels[logLevel];
                if (logLevel === undefined && this._logger) {
                    this._logger.error(`Unknown log level ${logLevels[loggers[i]]}`);
                    this._logger.debug(`Logger Levels: ${JSON.stringify(this.loggerLevels)}`);
                    continue;
                }
            }
            this.getLogger(loggers[i]).logLevel = logLevel;
        }
        if (this._logger) {
            this._logger.debug('Done setting log levels based on LOGGER_LEVELS env variable.');
        }
    }

    // TODO Implement levelName(level) to give us a name for pretty printing
}
