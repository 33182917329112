<template>
    <v-switch v-bind="$attrs" v-model="defaultedValue"></v-switch>
</template>

<script>
export default {
    name: 'default-switch',
    props: {
        value: [Boolean],
        default: [Boolean],
    },
    computed: {
        defaultedValue: {
            get: function get() {
                // If our v-model value is undefined, return the default value
                if (this.value === undefined) {
                    return this.default;
                }
                return this.value;
            },
            set: function set(value) {
                this.$emit('input', value);
            },
        },
    },
};
</script>
