<template>
  <div>
    <v-app-bar app color="indigo" dark dense>
      <v-btn outlined v-on:click="$router.go(-1)">
        <v-icon left>mdi-arrow-left</v-icon>Cancel
      </v-btn>
      <v-toolbar-title style="margin-left: 40px">
        {{meter['Service Address']}} {{photoName}} Photo
      </v-toolbar-title>
    </v-app-bar>
    <v-main>
      <div style="position: relative">
        <div v-if="previewActive" id="photo-preview-instructions"
            class="photo-capture-instructions">
          Tap the preview to capture a photo
        </div>
        <video id="photo-preview"
          v-show="previewActive"
          v-bind:srcObject.prop="mediaStream"
          v-on:click="capturePhoto"
          autoplay playsinline
          style="width: 100%"></video>
        <flex-photo v-if="capturedPhotoObjUrl" v-bind:src="canvas" elemStyle="width: 100%">
        </flex-photo>
        <div v-if="capturedPhotoObjUrl" style="padding: 20px 20px 10px 20px">
          <v-btn id="photo-use-button" block dark color="indigo" v-on:click="choosePhoto">
            Use
          </v-btn>
        </div>
        <div v-if="capturedPhotoObjUrl" style="padding: 10px 20px 20px 20px">
          <v-btn id="photo-retake-button" block dark color="indigo" v-on:click="retakePhoto">
            Retake
          </v-btn>
        </div>
      </div>
    </v-main>
  </div>
</template>

<script>
import Vue from 'vue';
import loadImage from 'blueimp-load-image';
import {
    appData, getMeterData, updateMeterData,
} from '../../../../../meter-data';
import { API_BASE } from '../../../../../site-consts';
import { PHOTOS_BY_TYPE } from '../../../../../meter-props';
import QueuedTransfer from '../../../../../QueuedTransfer';
import flexPhoto from '../../../../../components/flex-photo.vue';
import appLogger from '../../../../../logger';

const moduleLogger = appLogger.getLogger('photo-capture');
// Log levels are critical, error, warning, info, debug, and debugall
// moduleLogger.logLevel = moduleLogger.loggerLevels.debug;

const USE_GRAB_FRAME = true;
const sleeptime = 10000;
function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}
export default {
    components: {
        flexPhoto: flexPhoto,
    },
    props: {
        meter_id: String,
        photo_type: String,
    },
    data: function data() {
        const flogger = moduleLogger.getLogger('data');
        flogger.debug(`Creating initial data meter ${this.meter_id} photo type ${this.photo_type}`);
        return {
            meter: {},
            photoName: '',
            mediaStream: null,
            previewActive: false,
            capturePhotoBlob: null,
            capturedPhotoObjUrl: '',
            capturedPhotoDataUrl: '',
            canvas: '',
        };
    },
    methods: {
        capturePhoto: async function capturePhoto() {
            const flogger = moduleLogger.getLogger('capturePhoto');
            flogger.info('Preview touched. Capturing photo.');
            const imageCapture = new ImageCapture(this.mediaStream.getVideoTracks()[0]);
            // imglobal = imageCapture;
            let photoBlob;
            // An Android OS upgrade at the end of 2019 must have broken takePhoto() and made it
            // unstable. If the tablet is in certain orientations, it'll throw an error and enter
            // a situation where it looks like the camera crashed. Switching
            // to grabFrame() which seems to be more stable, but a lower quality picture.
            flogger.debug(`USE_GRAB_FRAME: ${USE_GRAB_FRAME}`);
            if (USE_GRAB_FRAME) {
                try {
                    const bitmap = await imageCapture.grabFrame();
                    // grabFrame returns a bitmap vs takePhoto returns a blob. We'll use a canvas
                    // element to convert from a bitmap to blob.
                    const canvas = document.createElement('canvas');
                    canvas.width = bitmap.width;
                    canvas.height = bitmap.height;
                    canvas.getContext('2d').drawImage(bitmap, 0, 0);
                    photoBlob = await new Promise((resolve) => {
                        canvas.toBlob(resolve);
                    });
                    flogger.debug('Photo captured with grabFrame()');
                } catch (error) {
                    flogger.error(`grabFrame() raised an error: ${JSON.stringify(error)}`, error);
                    throw error;
                }
            } else {
                try {
                    photoBlob = await imageCapture.takePhoto();
                    flogger.debug('Photo captured with takePhoto!');
                } catch (error) {
                    flogger.error(`takePhoto() raised an error: ${JSON.stringify(error)}`, error);
                    const videoTrack = this.mediaStream.getVideoTracks()[0];
                    flogger.debug(`MediaStream Video Track Info: enabled: ${videoTrack.enabled}, readyState: ${videoTrack.readyState}`);
                    // Before we call takePhoto, the readyState will be "live". After we crash, the
                    // readyState will be "ended". We then have to wait somewhere between 5-10
                    // seconds before trying to get a mediaStream again. My guess is the camera
                    // hardware or driver is resetting.
                    flogger.debug(`Sleeping for ${sleeptime}ms before getting a new media stream`);
                    await sleep(sleeptime);
                    this.mediaStream = await navigator.mediaDevices.getUserMedia({
                        video: {
                            facingMode: 'environment',
                        },
                    });
                    flogger.debug(`New media stream loaded. ReadyState: ${this.mediaStream.getVideoTracks()[0].readyState}`);
                    throw error;
                }
            }
            this.capturePhotoBlob = photoBlob;
            this.capturedPhotoObjUrl = URL.createObjectURL(photoBlob);
            this.stopLivePreview();
            loadImage(photoBlob, (img, data) => {
                const loadImageLogger = flogger.getLogger('loadImageCallback');
                loadImageLogger.debug('In loadImage callback function');
                loadImageLogger.debugall(`Image: ${JSON.stringify(img)}, Data: ${JSON.stringify(data)}`);
                if (img.type === 'error') {
                    loadImageLogger.error(`An error object was passed as the first argument to the loadImage callback. Error: ${JSON.stringify(img)}`);
                } else {
                    loadImageLogger.debug('Callback argument is not an error. Setting canvas property.');
                    this.canvas = img;
                    loadImageLogger.debug('Creating our base64 data of the image by resizing until its below 4MB ...');
                    let imgQuality = 1.0;
                    let base64data = img.toDataURL('image/jpeg', imgQuality);
                    while (base64data.length > 4 * 1024 * 1024 && imgQuality > 0) {
                        imgQuality -= 0.1;
                        loadImageLogger.debug(`Image too big, reducing quality to ${imgQuality}`);
                        base64data = img.toDataURL('image/jpeg', imgQuality);
                    }
                    loadImageLogger.debug(`Resizing complete. Setting capturedPhotoDataUrl to ${base64data.substring(0, 20)} ... ${base64data.substring(base64data.length - 20)}`);
                    this.capturedPhotoDataUrl = base64data;
                }
            },
            {
                canvas: true,
                meta: true,
                orientation: true,
            });
            flogger.debug('Emitting photo-captured event');
            this.$emit('photo-captured', photoBlob);
            flogger.debug('Exiting capturePhoto()');
        },
        startLivePreview: function startLivePreview() {
            const flogger = moduleLogger.getLogger('startLivePreview');
            flogger.debug('Starting preview');
            navigator.mediaDevices.getUserMedia({
                // video: true,
                video: {
                    facingMode: 'environment',
                },
            }).then((mediaStream) => {
                flogger.debug('Media stream found. Setting preview active.');
                this.mediaStream = mediaStream;
                this.previewActive = true;
            });
            flogger.debug('Exiting startLivePreview()');
        },
        stopLivePreview: function stopLivePreview() {
            const flogger = moduleLogger.getLogger('startLivePreview');
            flogger.debug('Stopping live preview ...');
            if (this.mediaStream) {
                this.mediaStream.getVideoTracks().forEach((track) => {
                    flogger.debug(`Stopping track ${JSON.stringify(track)}`);
                    track.stop();
                });
            } else {
                flogger.debug("Our mediaStream isn't set. Nothing to do.");
            }
            this.previewActive = false;
            flogger.debug('Exiting stopLivePreview()');
        },
        retakePhoto: function retakePhoto() {
            const flogger = moduleLogger.getLogger('retakePhoto');
            flogger.info('Retake button pressed');
            this.capturePhotoBlob = null;
            this.capturedPhotoObjUrl = '';
            this.startLivePreview();
        },
        choosePhoto: function choosePhoto() {
            const flogger = moduleLogger.getLogger('choosePhoto');
            flogger.info('Use button pressed');
            this.$emit('photo-chosen', this.capturePhotoBlob);
            const { meter } = this;
            // const photoKey = `Image ${this.photo_type} URL`;
            let photoKey;
            let capturedPhotoName;
            if (this.photo_type === 'town-attention') {
                photoKey = 'Photo URL Town Attention';
                capturedPhotoName = 'Town Attention';
            } else {
                photoKey = PHOTOS_BY_TYPE[this.photo_type].key;
                capturedPhotoName = PHOTOS_BY_TYPE[this.photo_type].name;
            }
            flogger.debug('Queueing photo upload ...');
            appData.uploadQueue.addQueuedTransfer(new QueuedTransfer(
                'POST',
                `${API_BASE}/meters/${this.meter.ID}/photos/${this.photo_type}`,
                {
                    name: `${this.meter['Service Address']} ${capturedPhotoName} Photo`,
                    body: this.capturedPhotoDataUrl,
                    completedCallback: (queuedTransfer) => {
                        flogger.debug('Photo transfer complete. Executing completed callback.');
                        if (queuedTransfer.xhr.status < 200 || queuedTransfer.xhr.status >= 300) {
                            console.log(`Non-2XX Status Code on transfer (${queuedTransfer.xhr.status}).`);
                            console.log('Leaving image as local copy.');
                            return;
                        }
                        // Now that the transfer is complete, change our URL for the photo to be
                        // the server URL, instead of the local object URL.
                        const newUrl = queuedTransfer.xhr.getResponseHeader('Location');
                        flogger.debug(`Changing image URL to be ${newUrl}`);
                        // We don't need to use Vue.set here b/c we should already have a key. The
                        // key was set to the object URL when we started the transfer.
                        Vue.set(meter, photoKey, newUrl);
                        // meter[photoKey] = newUrl;
                        if (appData.modifiedMeters[meter.ID]) {
                            Vue.set(appData.modifiedMeters[meter.ID], photoKey, newUrl);
                            // appData.modifiedMeters[meter.ID][photoKey] = newUrl;
                        }
                        flogger.debug('Photo upload callback completed');
                    },
                    maxRetries: -1,
                },
            ));
            if (!appData.uploadQueue.running) {
                appData.uploadQueue.startNextTransfer();
            }
            // We use Vue.set b/c the key might not exist yet if no photo has been taken
            // Vue.set(meter, photoKey, URL.createObjectURL(this.capturePhotoBlob));
            flogger.debug(`Setting ${photoKey} on meter ${meter.ID} to ${this.canvas}`);
            Vue.set(meter, photoKey, this.canvas);
            if (appData.modifiedMeters[meter.ID]) {
                Vue.set(appData.modifiedMeters[meter.ID], photoKey, this.canvas);
            }
            // After we choose the photo, go to the meter's edit page
            if (this.$route.query.fromPreview) {
                flogger.debug('The fromPreview query parameter is set. We must have come retaken a photo. Going back 2 pages in our history.');
                // If we came from the previw page, we need to go back 2
                this.$router.go(-2);
            } else {
                flogger.debug('The fromPreview query parameter is not set. We must have come from the meter details page. Going back 1 page in our history.');
                this.$router.go(-1);
            }
        },
    },
    created: async function created() {
        const flogger = moduleLogger.getLogger('created');
        flogger.debug(`Creating photo capture view for meter ${this.meter_id} photo type ${this.photo_type}`);
        if (!appData.metersById[this.meter_id]) {
            flogger.debug("We're missing data for this meter. We'll try loading the data ...");
            await getMeterData();
        }
        if (!appData.metersById[this.meter_id]) {
            flogger.debug("We're still missing our data. Try updating ...");
            // If our meter still isn't there, check to see if we're missing updates
            await updateMeterData();
        }
        if (!appData.metersById[this.meter_id]) {
            // The meter ID in the URL is invalid
            // TODO Display a 404 page with a link to the main page
            flogger.warning(`We were unable to locate the details for meter ${this.meter_id}`);
            return;
        }
        this.meter = appData.metersById[this.meter_id];
        if (!PHOTOS_BY_TYPE[this.photo_type]) {
            this.invalidPhotoType = true;
            return;
        }
        if (this.photo_type === 'town-attention') {
            this.photoName = 'Town Attention';
        } else {
            this.photoName = PHOTOS_BY_TYPE[this.photo_type].name;
        }
        this.photoName = PHOTOS_BY_TYPE[this.photo_type].name;
    },
    activated: function activated() {
        const flogger = moduleLogger.getLogger('activated');
        flogger.debug(`View activated for /meters/${this.meter_id}/photos/${this.photo_type}`);
        this.startLivePreview();
    },
    deactivated: function activated() {
        const flogger = moduleLogger.getLogger('deactivated');
        flogger.debug(`View deactivated for /meters/${this.meter_id}/photos/${this.photo_type}`);
        this.stopLivePreview();
        this.capturePhotoBlob = null;
        this.capturedPhotoObjUrl = '';
        this.capturedPhotoDataUrl = '';
    },
    destroyed: function destroyed() {
        const flogger = moduleLogger.getLogger('destroyed');
        flogger.debug(`View destroyed for /meters/${this.meter_id}/photos/${this.photo_type}`);
        this.stopLivePreview();
    },
};
</script>
