// This file contains code for filtering data
import logger from './logger';

const moduleLogger = logger.getLogger('data-filters');
moduleLogger.logLevel = moduleLogger.loggerLevels.info;

export function filterData(data, filterSpec) {
    // This function takes a filter specification (probably generated with the filter-bar
    // component) and applies it to dataList (a list of data objects) to return a filtered
    // list of data.
    const flogger = moduleLogger.getLogger('filterData');
    flogger.debug(`Filtering ${data.length} items`);
    const filteredData = [];
    const filterStartTime = new Date();
    for (let i = 0; i < data.length; i++) {
        let filtered = false;
        for (let j = 0; j < filterSpec.length; j++) {
            if (filterSpec[j].prop.filter(data[i], filterSpec[j].value)) {
                // The below log lines are used to debug situations where not all meters
                // are being shown.
                flogger.debugall(`Meter filtered out on ${filterSpec[j].prop.label}`);
                flogger.debugall(data[i]);
                filtered = true;
                break;
            }
        }
        if (!filtered) {
            filteredData.push(data[i]);
        }
    }
    const filterTime = new Date() - filterStartTime;
    flogger.info(`Took ${filterTime / 1000} seconds to filter ${data.length} items down to ${filteredData.length}.`, { filterTime: filterTime });
    return filteredData;
}

export function addFilterSpecToUrl(filterSpec) {
    const flogger = moduleLogger.getLogger('filterValueChanged');
    flogger.logLevel = moduleLogger.loggerLevels.debug;
    flogger.debug('Updating URL with filter values ...');
    const searchParams = new URLSearchParams(document.location.search);
    for (let i = 0; i < filterSpec.length; i++) {
        const filterVal = Array.from(filterSpec[i].value);
        filterVal.sort();
        const defaultValue = filterSpec[i].prop.defaultValue(filterSpec[i].prop.choices()).sort();
        if (JSON.stringify(filterVal) !== JSON.stringify(defaultValue)) {
            searchParams.set(filterSpec[i].prop.label, filterVal.join(','));
        } else {
            searchParams.delete(filterSpec[i].prop.label);
        }
    }
    const searchParamsStr = searchParams.toString();
    const searchStr = searchParamsStr.length > 0 ? `?${searchParamsStr}` : '';
    const newUrl = `${document.location.pathname}${searchStr}`;
    window.history.replaceState(null, document.title, newUrl);
}

export function updateFilterSpecFromUrl(filterSpec, query) {
    const flogger = moduleLogger.getLogger('updateFilterSpecFromUrl');
    flogger.logLevel = moduleLogger.loggerLevels.debug;
    flogger.debug(`Updating filter values from query values ${JSON.stringify(query)}`);
    // TODO Do we want to decouple from vue and not pass in this.$route.query?
    for (let i = 0; i < filterSpec.length; i++) {
        if (query[filterSpec[i].prop.label]) {
            flogger.info(`Filter value found on URL for ${filterSpec[i].prop.label}. Updating filter value.`);
            const vals = query[filterSpec[i].prop.label].split(',');
            for (let j = 0; j < vals.length; j++) {
                if (vals[j] === 'true') {
                    vals[j] = true;
                }
                if (vals[j] === 'false') {
                    vals[j] = false;
                }
            }
            filterSpec[i].value = vals;
        }
    }
}
