// This LocalStorageObj class is intented to make it easier to manage objects that should be
// auto-magically saved to local storage on any change.

import appLogger from './logger';

const logger = appLogger.getLogger('LocalStorageObj');
logger.logLevel = logger.loggerLevels.debug;

export default class LocalStorageObj {
    #localStorageKey;

    #obj;

    #options;

    constructor(key, options) {
        const flogger = logger.getLogger('LocalStorageObj()');
        flogger.debug(`Creating a LocalStorage obj for key ${key}`);
        this.#localStorageKey = key;
        this.#options = options || {};
        this.#options.throwErrorOnSave = this.#options.throwErrorOnSave || false;
        this.#options.defaultObj = this.#options.defaultObj || {};
        // We use a proxy object so that we can intercept all of the gets and sets
        return new Proxy(this, this);
    }

    get(target, property) {
        const flogger = logger.getLogger('get');
        if (this.#obj === undefined) {
            flogger.debug(`No object pulled from local storage yet. Pulling key ${this.#localStorageKey}.`);
            this.loadFromLocalStorage();
        }
        return this.#obj[property];
    }

    set(target, property, value) {
        const flogger = logger.getLogger('set');
        if (this.#obj === undefined) {
            flogger.debug(`No object pulled from local storage yet. Pulling key ${this.#localStorageKey}.`);
            this.loadFromLocalStorage();
        }
        this.#obj[property] = value;
        this.saveToLocalStorage();
        return true;
    }

    loadFromLocalStorage() {
        const flogger = logger.getLogger('loadFromLocalStorage');
        // We prefer to store our data in localStorage. But, if that's not supported in our
        // browser, we'll store it in sessionStorage. Session storage only lasts until the
        // browser is restarted. So, lets check to see if the data is in there first.
        let storedData = sessionStorage.getItem(this.#localStorageKey);
        if (!storedData) {
            flogger.debug('Key not found in session storage. Checking local storage ...');
            storedData = localStorage.getItem(this.#localStorageKey);
        }
        if (!storedData) {
            flogger.warning('Key not found in local or session storage. Creating new obj.');
            this.#obj = this.#options.defaultObj;
            this.saveToLocalStorage();
        } else {
            flogger.debug(`Stored data found. Length: ${storedData.length}`);
            flogger.debugall(`Stored data string: ${JSON.stringify(storedData)}`);
            this.#obj = JSON.parse(storedData);
            flogger.debugall(`Stored data parsed: ${JSON.stringify(this.#obj)}`);
        }
    }

    saveToLocalStorage() {
        const flogger = logger.getLogger('saveToLocalStorage');
        // We can only save strings to local storage. Convert to a JSON string.
        const objStr = JSON.stringify(this.#obj);
        flogger.debug(`Length of data being saved to local storage: ${objStr.length}`);
        try {
            localStorage.setItem(this.#localStorageKey, objStr);
        } catch (err) {
            flogger.warning('WARNING Unable to store data in localstorage. Error: '
                + `${err.message}. Length: ${objStr.length}.`);
            try {
                sessionStorage.setItem(this.#localStorageKey, objStr);
            } catch (sessStorageError) {
                flogger.error('WARNING Unable to store data in sessionstorage. Error: '
                    + `${sessStorageError.message}`);
                if (this.#options.throwErrorOnSave) {
                    throw sessStorageError;
                }
            }
        }
    }
}
