<template>
  <div>
    <v-dialog v-model="open">
      <v-card>
        <v-card-title>
          Settings
          <div class="flex-grow-1"></div>
          <v-btn icon v-on:click.stop="open = false">
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div style="margin-top: 10px; font-size: 125%; border-bottom: 1px solid #AAA">
            Feature Toggles
          </div>
          <div style="padding-left: 10px">
            <div style="display: flex; align-items: center">
              <v-switch label="Auto-Save Data Changes Locally"
                dense v-model="autoSaveOnType"></v-switch>
              <v-btn icon v-on:click.stop="showInfo('Auto-Save Locally When Typing')">
                <v-icon>mdi-information-outline</v-icon>
              </v-btn>
            </div>
            <div style="display: flex; align-items: center">
              <v-switch label="Check for Changes When Typing"
                dense v-model="checkChangesOnType"></v-switch>
              <v-btn icon v-on:click.stop="showInfo('Check for Changes When Typing')">
                <v-icon>mdi-information-outline</v-icon>
              </v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="infoOpen">
      <v-card>
        <v-card-title>
          {{infoName}}
          <div class="flex-grow-1"></div>
          <v-btn icon v-on:click.stop="infoOpen = false">
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>{{infoDesc}}</v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import settings from '../settings';
import { saveAppDataToStorage } from '../meter-data';
import logger from '../logger';

const settingsInfo = {
    'Auto-Save Locally When Typing': 'When enabled, the application will attempt to save data to local storage whenever a character is typed in meter fields.',
    'Check for Changes When Typing': 'When enabled, the application will compare the values in all fields to the last value obtained from server. If there are any differences, the application is updated to show that there are unsaved changes.',
};

export default {
    name: 'settings-dialog',
    props: {
        value: Boolean,
    },
    computed: {
        open: {
            get: function get() {
                return this.value;
            },
            set: function set(value) {
                this.$emit('input', value);
            },
        },
    },
    data: function data() {
        return {
            autoSaveOnType: settings.autoSaveOnType,
            checkChangesOnType: settings.checkChangesOnType,
            infoOpen: false,
            infoName: '',
            infoDesc: '',
        };
    },
    methods: {
        showInfo: function showInfo(field) {
            if (settingsInfo[field] === undefined) {
                // If we don't have any additional info about this setting, we don't have
                // anything to do
                return;
            }
            this.infoName = field;
            this.infoDesc = settingsInfo[field];
            this.infoOpen = true;
        },
    },
    watch: {
        autoSaveOnType: {
            handler: function handler(newValue) {
                const flogger = this.logger.getLogger('autoSaveOnType-Changed');
                if (newValue === true) {
                    flogger.info('Setting Auto-Save Locally When Typing was changed to true. Adding watch to application data.');
                    window.appDataUnwatch = window.vueApp.$watch('aData', function aDatahandler() {
                        saveAppDataToStorage();
                    }, { deep: true });
                } else if (window.appDataUnwatch) {
                    flogger.info('Setting Auto-Save Locally When Typing was changed to false. Clearing watch on application data.');
                    window.appDataUnwatch();
                }
            },
        },
    },
    created: function created() {
        this.logger = logger.getLogger('settings-dialog');
        // this.logger.logLevel = this.logger.loggerLevels.debug;
    },
};
</script>
