// This file contains our ServerLogger object, which can be attached to a log-monster logger to
// push logs to an API endpoint.
//
// In our Meter Swap App setup, we have the root logger that's created in ./logger.js. That root
// logger gets the following handlers attached, in this order:
//   1. Console Log Handler - Prints our logs to console.log() so we can see them in our
//        browser. Not important for this file. This handler has no impact on this file's code.
//   2. JSON Formatter - Transforms the msg property of the log message to be a JSON string
//        containing the logger name, log level, message, and any additional properties passed to
//        the log() call.
//   3. The logMonsterHandler function in an instance of our ServerLogger class.
// So, we're the last handler and everything we need should be contained in a JSON string on the
// msg property of the object passed to our logMonsterHandler().

import { API_BASE } from './site-consts';

// AWS API Gateway has a Total combined size of request line and header values max of 10240 bytes.
// AWS lambda has a max invocation payload of 6 MB.
export default class ServerLogger {
    constructor(uploadInterval = 5000, maxSize = 4 * 1024 * 1024) {
        this.uploadInterval = uploadInterval;
        this.maxSize = maxSize;
        this.queuedUploads = [];
        this.activeTimeoutId = undefined;
        this.logMonsterHandler.bind(this);
    }

    async logMonsterHandler(logMsg) {
        // This code will ensure that each of our messages are small enough to be uploaded.
        // Worst case scenario is that each message is in its own call to the API.
        const logMsgLength = logMsg.msg.length + 40;
        if (logMsgLength > this.maxSize) {
            // To keep the size of our payload under maxSize, we need to subtract the following:
            //   40 characters for the ' ... XXXXXXXXXXXX Characters Removed ... '
            //   40 characters for {timestamp:XXXXXXXX,message:""}
            const substringSize = (this.maxSize - 40 - 40) / 2;
            logMsg.msg = `${logMsg.msg.substring(0, substringSize)} ... ${logMsg.msg.length - (substringSize * 2)} Characters Removed ... ${logMsg.msg.substring(logMsg.msg.length - substringSize)}`;
        }

        if (JSON.stringify(this.queuedUploads).length + logMsgLength > this.maxSize) {
            clearTimeout(this.activeTimeoutId);
            this.activeTimeoutId = undefined;
            await this.uploadEvents();
        }
        // When we make a call to our /logs API endpoint, we just JSON stringify the
        // queuedUploads array for the body. So, its important that the objects in the array
        // match the format expected by our /logs API. Currently, the body is used for the
        // logEvents attribute of the putLogEvents method.
        // https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/CloudWatchLogs.html#putLogEvents-property
        this.queuedUploads.push({
            // From AWS docs for the timestamp attr ... The time the event occurred, expressed
            // as the number of milliseconds after Jan 1, 1970 00:00:00 UTC.
            timestamp: new Date().getTime(),
            message: logMsg.msg,
        });
        // TODO Check if we already have enough logs to upload
        // TODO Don't do another timeout if this.activeTimeoutId is set.
        if (this.queuedUploads.length === 1) {
            this.activeTimeoutId = setTimeout(this.uploadEvents.bind(this), this.uploadInterval);
        }
    }

    async uploadEvents() {
        // Its important that we clear activeTimeoutId as early as possible, so we don't miss
        // messages.
        this.activeTimeoutId = undefined;
        const uploadBody = JSON.stringify(this.queuedUploads);
        // TODO Upgrade this function to do the following:
        //   1. Split up queuedUploads if it exceeds any of these limits:
        //      a. AWS API Gateway has a Total combined size of request line and header values
        //         max of 10240 bytes.
        //      b. AWS lambda has a max invocation payload of 6 MB.
        //      c. The maximum number of log events in a batch is 10,000. And additional limits
        //         detailed here: https://docs.aws.amazon.com/AmazonCloudWatchLogs/latest/APIReference/API_PutLogEvents.html
        //   2. If more messages remain, repeat up to 4 times per second.
        //   3. Check if queuedUploads is empty. This could happen if a message comes in while
        //      we're in the middle of processing. We go ahead and send it, but there's also a
        //      timer pending.
        this.queuedUploads = [];
        // This function is called from a timer
        const resp = await fetch(`${API_BASE}/logs`, {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            body: uploadBody,
        });
        // TODO Add a retry mechanism
        // TODO Update the API endpoint and here to capture the log sequence number to speed up
        //      the API call.
        console.log(`Log upload complete. ${resp.status} ${await resp.text()}`);
    }
}
