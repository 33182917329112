<template>
    <div>
      <v-app-bar app color="indigo" dark dense>
        <v-btn outlined v-on:click="goBack()">
         <v-icon left>mdi-arrow-left</v-icon>Back
        </v-btn>
        <v-toolbar-title style="margin-left: 40px">
            {{meter['Service Address']}} {{photoName}} Photo
        </v-toolbar-title>
      </v-app-bar>
      <v-main>
        <flex-photo v-bind:src="photoSrc" style="width: 100%"></flex-photo>
        <div v-if="appData.access !== 'RO'" style="padding: 20px">
          <v-btn block dark color="indigo" v-on:click="capturePhoto">Retake Photo</v-btn>
        </div>
      </v-main>
    </div>
</template>

<script>
import {
    appData, getMeterData, updateMeterData,
} from '../../../../meter-data';
import flexPhoto from '../../../../components/flex-photo.vue';
import { PHOTOS_BY_TYPE } from '../../../../meter-props';

export default {
    components: {
        flexPhoto: flexPhoto,
    },
    props: {
        meter_id: String,
        photo_type: String,
    },
    data: function data() {
        return {
            appData: appData,
            meter: {},
            invalidId: false,
            photoName: '',
            photoSrc: '',
        };
    },
    methods: {
        goBack: function goBack() {
            this.$router.go(-1);
            // this.$router.push(`/meters/${this.meter.ID}`);
        },
        capturePhoto: function capturePhoto() {
            this.$router.push(`/meters/${this.meter.ID}/photos/${this.photo_type}/capture?fromPreview=true`);
        },
    },
    created: async function created() {
        console.log(`Meter ID: ${this.meter_id}`);
        if (!appData.metersById[this.meter_id]) {
            console.log("We're missing data for this meter. We'll try loading the data ...");
            await getMeterData();
        }
        if (!appData.metersById[this.meter_id]) {
            console.log("We're still missing our data. Try updating ...");
            // If our meter still isn't there, check to see if we're missing updates
            await updateMeterData();
        }
        if (!appData.metersById[this.meter_id]) {
            // If still nothing, set invalidId
            this.invalidId = true;
            return;
        }
        if (appData.modifiedMeters[this.meter_id]) {
            console.log('Meter found in the modified meters list');
            this.meter = appData.modifiedMeters[this.meter_id];
        } else {
            this.meter = appData.metersById[this.meter_id];
        }
        if (this.photo_type !== 'town-attention' && !PHOTOS_BY_TYPE[this.photo_type]) {
            this.invalidPhotoType = true;
            return;
        }
        if (this.photo_type === 'town-attention') {
            this.photoName = 'Town Attention';
            this.photoSrc = this.meter['Photo URL Town Attention'];
        } else {
            this.photoName = PHOTOS_BY_TYPE[this.photo_type].name;
            this.photoSrc = this.meter[PHOTOS_BY_TYPE[this.photo_type].key];
        }
    },
};
</script>
