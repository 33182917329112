<template>
    <div style="position: relative">
    <video ref="video" v-bind:srcObject.prop="mediaStream"
        autoplay playsinline style="display: none"></video>
    <canvas ref="canvas" style="width: 100%">Canvas tag not supported</canvas>
    <div style="text-align: center; padding: 20px">{{infoText}}</div>
    <div style="padding: 20px">
        <v-btn v-if="!lookForBarcode" block dark color="indigo"
            v-on:click="captureBarcode">Capture Barcode</v-btn>
        <v-btn v-if="lookForBarcode" block dark color="indigo"
            v-on:click="infoText = ''; lookForBarcode = false">Cancel</v-btn>
    </div>
    </div>
</template>

<script>
import ZXing from '../zxing_reader';
import appLogger from '../logger';

const moduleLogger = appLogger.getLogger('barcode-scanner');
moduleLogger.logLevel = moduleLogger.loggerLevels.debug;
const BARCODE_RE = /^[0-9]{8,9}$/;

export default {
    name: 'barcode-scanner',
    props: {
        heightToWidthRatio: {
            type: Number,
            default: 0.5,
        },
    },
    data: function data() {
        return {
            mediaStream: null,
            animationFrameRequestId: null,
            infoText: '',
            lookForBarcode: false,
        };
    },
    methods: {
        firstFrameLoaded: function firstFrameLoaded() {
            const flogger = moduleLogger.getLogger('firstFrameLoaded');
            flogger.debug('In firstFrameLoaded()');
            const videoElem = this.$refs.video;
            videoElem.removeEventListener('loadeddata', this.firstFrameLoaded);
            flogger.debug(`Video width: ${videoElem.videoWidth}, height: ${videoElem.videoHeight}`);
            const videoHeightWidthRatio = videoElem.videoHeight / videoElem.videoWidth;
            flogger.debug(`Setting canvas height to ${videoHeightWidthRatio * this.$refs.canvas.width}`);
            this.$refs.canvas.height = videoHeightWidthRatio * this.$refs.canvas.width;
            this.drawCanvas();
            flogger.debug('Exiting firstFrameLoaded()');
        },
        startLivePreview: async function startLivePreview() {
            const flogger = moduleLogger.getLogger('startLivePreview');
            flogger.debug('Starting camera ...');
            this.mediaStream = await navigator.mediaDevices.getUserMedia({
                video: {
                    facingMode: 'environment',
                },
            });
            this.$refs.video.addEventListener('loadeddata', this.firstFrameLoaded);
            flogger.debug('Camera started');
        },
        drawCanvas: function drawCanvas() {
            // All of the logging calls in this function are at a debugall level
            const flogger = moduleLogger.getLogger('drawCanvas');
            flogger.debugall('In drawCanvas()');
            flogger.debugall(`Canvas width: ${this.$refs.canvas.width} height: ${this.$refs.canvas.height}`);
            const ctx = this.$refs.canvas.getContext('2d');
            // Draw the video
            ctx.drawImage(this.$refs.video, 0, 0, this.$refs.canvas.width,
                this.$refs.canvas.height);
            // Draw the mask
            const maskWidth = this.$refs.canvas.width * 0.9;
            const maskHeight = maskWidth * this.heightToWidthRatio;
            flogger.debugall(`Mask width: ${maskWidth} height: ${maskHeight}`);
            const alphaWidth = (this.$refs.canvas.width - maskWidth) / 2;
            const alphaHeight = (this.$refs.canvas.height - maskHeight) / 2;
            flogger.debugall(`Alpha width: ${alphaWidth} height: ${alphaHeight}`);
            ctx.fillStyle = 'rgba(0, 0, 0, 0.7)';
            // Draw the top alpha block
            ctx.fillRect(0, 0, this.$refs.canvas.width, alphaHeight);
            // Draw the bottom alpha block
            ctx.fillRect(0, this.$refs.canvas.height, this.$refs.canvas.width, -alphaHeight);
            // Draw the left alpha block
            ctx.fillRect(0, alphaHeight, alphaWidth, maskHeight);
            // Draw the right alpha block
            ctx.fillRect(this.$refs.canvas.width, alphaHeight, -alphaWidth, maskHeight);
            // Draw a line through the middle
            ctx.strokeStyle = 'rgba(255, 0, 0, 0.9)';
            ctx.beginPath();
            ctx.moveTo(this.$refs.canvas.width * 0.02, this.$refs.canvas.height / 2);
            ctx.lineTo(this.$refs.canvas.width * 0.98, this.$refs.canvas.height / 2);
            ctx.stroke();

            if (this.lookForBarcode) {
                // We're currently looking for a barcode. When we're looking, we look on every
                // frame redraw until we see a barcode.
                const imageData = this.$refs.canvas.getContext('2d').getImageData(
                    alphaWidth,
                    (this.$refs.canvas.height / 2) - 2,
                    maskWidth,
                    4,
                );
                // const imageData = this.$refs.canvas.getContext('2d').getImageData(
                //     alphaWidth, alphaHeight, maskWidth, maskHeight);
                const sourceBuffer = imageData.data;
                flogger.debugall('Allocating memory and copying data for zxing library ...');
                const buffer = this.zxing._malloc(sourceBuffer.byteLength);
                this.zxing.HEAPU8.set(sourceBuffer, buffer);
                flogger.debugall('Copy complete. Looking for barcode ...');
                // TODO Add map of barcode types to top
                const result = this.zxing.readBarcodeFromPixmap(buffer, maskWidth, maskHeight, true, 'CODE_128');
                flogger.debugall(`Barcode read complete. Result: ${JSON.stringify(result)}`);
                this.zxing._free(buffer);
                if (result.error) {
                    flogger.warning(`Error from barcode scan: ${result.error}`);
                    return;
                }
                if (result.text) {
                    if (!BARCODE_RE.test(result.text)) {
                        flogger.debug(`Barcode scanned (${result.text}) doesn't match our regular expression.`);
                        this.infoText = 'Barcode isn\'t 8-9 numbers. Try again ...';
                    } else {
                        flogger.info(`Barcode of format ${result.format} found: ${result.text}`);
                        this.$emit('barcodeFound', {
                            format: result.format,
                            text: result.text,
                        });
                        this.infoText = 'Barcode Found!';
                        this.lookForBarcode = false;
                    }
                }
            }

            // Request to get called again on the next frame being drawn
            this.animationFrameRequestId = requestAnimationFrame(this.drawCanvas.bind(this));
        },
        captureBarcode: function captureBarcode() {
            const flogger = moduleLogger.getLogger('captureBarcode');
            flogger.info('Capture Barcode button pressed');
            this.infoText = 'Looking for barcode ...';
            this.lookForBarcode = true;
        },
        stopLivePreview: function stopLivePreview() {
            if (this.mediaStream) {
                this.mediaStream.getVideoTracks().forEach(function stopTrack(track) {
                    track.stop();
                });
            }
            if (this.animationFrameRequestId) {
                cancelAnimationFrame(this.animationFrameRequestId);
            }
        },
    },
    created: async function created() {
        this.startLivePreview();
        this.zxing = ZXing();
    },
    destroyed: function destroyed() {
        this.stopLivePreview();
    },
};
</script>
